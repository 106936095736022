import * as THREE from "three";

import pxUrl from "./assets/envmaps/5/px.hdr";
import nxUrl from "./assets/envmaps/5/nx.hdr";
import pyUrl from "./assets/envmaps/5/py.hdr";
import nyUrl from "./assets/envmaps/5/ny.hdr";
import pzUrl from "./assets/envmaps/5/pz.hdr";
import nzUrl from "./assets/envmaps/5/nz.hdr";

(window as any).THREE = THREE;
require("three/examples/js/loaders/RGBELoader");
require("three/examples/js/loaders/HDRCubeTextureLoader");
require("three/src/extras/PMREMGenerator");
let THRANY = THREE as any;


function loadEnvMap(gl: THREE.WebGLRenderer): Promise<THREE.Texture> {
    return new Promise(res => {
        new THRANY.HDRCubeTextureLoader()
            .setDataType(THREE.UnsignedByteType)
            .load([pxUrl, nxUrl, pyUrl, nyUrl, pzUrl, nzUrl], (texture: any) => {
                let pmremGenerator = new THRANY.PMREMGenerator(gl);
                texture.minFilter = THREE.LinearFilter;
                texture.magFilter = THREE.LinearFilter;
                texture.needsUpdate = true;
                let renderTarget = pmremGenerator.fromCubemap(texture);
                let resultTexture = renderTarget.texture;
                resultTexture.generateMipmaps = false;
                pmremGenerator.dispose();
                res(resultTexture);
            });
    });
}

let envMapPromise: Promise<THREE.Texture>;
export function getEnvMap(gl: THREE.WebGLRenderer) {
    if (!envMapPromise) {
        envMapPromise = loadEnvMap(gl);
    }
    return envMapPromise;
}