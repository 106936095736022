import React, { useEffect, MutableRefObject, useRef } from "react";
import { useFrame, useThree } from "react-three-fiber";
import { Color } from "three";
import { AnimatedValue } from "react-spring";

export type RenderManagerRef = {
  renderNow: () => void;
  takeScreenshot: () => Promise<string>;
};

interface RenderManagerProps {
  backgroundColor: AnimatedValue<{ backgroundColor: string | undefined }>;
}

export const RenderManager = React.forwardRef<
  RenderManagerRef,
  RenderManagerProps
>(({ backgroundColor }, ref) => {
  let { gl, scene, camera } = useThree();
  let screenShotQueue = useRef<((ss: string) => void)[]>([]);

  useEffect(() => {}, [scene, backgroundColor]);

  useEffect(() => {
    if (!ref) return;
    (ref as MutableRefObject<RenderManagerRef>).current = {
      renderNow: () => {
        gl.render(scene, camera);
      },
      takeScreenshot: () =>
        new Promise<string>((res) => {
          screenShotQueue.current.push(res);
        }),
    };
  }, [ref, gl, scene, camera]);

  useFrame(() => {
    let hasItems = screenShotQueue.current.length > 0;
    if (hasItems) {
      scene.background = new Color().setStyle(
        backgroundColor.backgroundColor.getValue()!
      );
    }
    gl.render(scene, camera);
    while (screenShotQueue.current.length > 0) {
      let cb = screenShotQueue.current.shift()!;
      cb(gl.domElement.toDataURL());
    }
    if (hasItems) {
      scene.background = null;
    }
  }, 1);

  return <></>;
});
