import { DataTexture, RGBAFormat, RepeatWrapping, LinearFilter } from "three"

export function generateNoiseTexture(width: number, height: number) {
    let size = width * height
    let data = new Uint8ClampedArray(4 * size)

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            let i = y * width + x
            let stride = i * 4
            data[stride] = Math.floor(Math.random() * 255) // r
            data[stride + 2] = Math.floor(Math.random() * 255) // b
        }
    }
    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            let i = y * width + x
            let stride = i * 4

            let x2 = (x - 37) & 255
            let y2 = (y - 17) & 255
            let i2 = y2 * width + x2
            let stride2 = i2 * 4

            data[stride + 1] = data[stride2] // r2->g
            data[stride + 3] = data[stride2 + 2] // b2->a
        }
    }

    let texture = new DataTexture(data, width, height, RGBAFormat)
    texture.wrapS = RepeatWrapping
    texture.wrapT = RepeatWrapping
    texture.magFilter = LinearFilter
    texture.minFilter = LinearFilter
    return texture
}
