import React, { useState } from "react";
import FileInput from "@brainhubeu/react-file-input";
import { Geometry, Vector3 } from "three";

import { loadScaffoldGeometry } from "./scaffolds";

import "./ScaffoldUpload.scss";

interface ScaffoldUploadProps {
  onUpdateScaffolds: (
    substrateScaffold: Geometry,
    inhibitorScaffold?: Geometry
  ) => void;
}
export const ScaffoldUpload: React.FC<ScaffoldUploadProps> = React.memo(
  ({ onUpdateScaffolds }) => {
    let [substrateScaffold, setSubstrateScaffold] = useState<Geometry>();
    let [substrateScaffoldCenter, setSubstrateScaffoldCenter] = useState<
      Vector3
    >();
    let [substrateScaffoldScaledBy, setSubstrateScaffoldScaledBy] = useState(1);
    let [inhibitorScaffold, setInhibitorScaffold] = useState<Geometry>();

    let updateSubstrateScaffoldModel = async (evt: any) => {
      let dataUrl = await loadFile(evt.value);
      let [scaffoldGeom, centerPoint, scaledBy] = await loadScaffoldGeometry({
        url: dataUrl,
        subtract: "self",
        fitToScale: 29,
      });
      if (substrateScaffold) {
        substrateScaffold.dispose();
      }
      setSubstrateScaffold(scaffoldGeom);
      setSubstrateScaffoldCenter(centerPoint);
      setSubstrateScaffoldScaledBy(scaledBy);
    };
    let updateInhibitorScaffoldModel = async (evt: any) => {
      let dataUrl = await loadFile(evt.value);
      let [scaffoldGeom] = await loadScaffoldGeometry({
        url: dataUrl,
        subtract: substrateScaffoldCenter!,
        initScale: substrateScaffoldScaledBy,
      });
      setInhibitorScaffold(scaffoldGeom);
    };
    let applyScaffolds = () => {
      onUpdateScaffolds(substrateScaffold!, inhibitorScaffold);
    };

    return (
      <div className="scaffoldUpload">
        <p>Upload a mesh .obj to use as a substrate for the fungal growths.</p>
        <FileInput
          className="input input--file input--meshUpload"
          onChangeCallback={updateSubstrateScaffoldModel}
        />
        <p>
          Optionally, upload a second mesh .obj to use as an inhibitor, forming
          negative space within your substrate. It should be in the same
          coordinate system as your substrate.
        </p>
        <FileInput
          className="input input--file input--inhibitorUpload"
          onChangeCallback={updateInhibitorScaffoldModel}
        />

        <button
          className="button button--submitUpload"
          disabled={!substrateScaffold}
          onClick={applyScaffolds}
        >
          <span className="sr-only">Upload</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.07 15.27">
            <path
              className="filled"
              d="M18.86,6.69c-.71,0-1.06.53-1.06,1.43s.35,1.41,1.06,1.41S19.91,9,19.91,8.12,19.56,6.69,18.86,6.69Z"
            />
            <path
              className="filled"
              d="M21.7,8.92c0,.4.26.6.72.59a1,1,0,0,0,1.1-.9V8a2.9,2.9,0,0,1-1,.24C22,8.33,21.7,8.48,21.7,8.92Z"
            />
            <path
              className="filled"
              d="M13.57,6.71c-.62,0-1,.46-1,1.37s.36,1.43,1,1.43,1-.56,1-1.36S14.26,6.71,13.57,6.71Z"
            />
            <path
              className="filled"
              d="M28.43,0H7.63a7.64,7.64,0,0,0,0,15.27h20.8A7.64,7.64,0,1,0,28.43,0ZM11.23,6.53V8.06c0,1.23-.59,1.94-1.8,1.94A1.69,1.69,0,0,1,7.61,8.06V6.53l0-1.45A.08.08,0,0,1,7.66,5h.55a.07.07,0,0,1,.07.07l0,1.44V8.06c0,.83.34,1.39,1.17,1.39s1.2-.56,1.2-1.39V6.53l0-1.45A.07.07,0,0,1,10.68,5h.5a.08.08,0,0,1,.07.07ZM13.72,10a1.26,1.26,0,0,1-1.13-.6V11a.07.07,0,0,1-.07.07H12A.07.07,0,0,1,12,11l0-2.69,0-2A.07.07,0,0,1,12,6.31h.51a.08.08,0,0,1,.07.07l0,.5a1.26,1.26,0,0,1,1.15-.64c1,0,1.49.81,1.49,1.86S14.67,10,13.72,10Zm2.78-.14a.07.07,0,0,1-.07.07h-.5a.07.07,0,0,1-.07-.07l0-2.41,0-2.35A.07.07,0,0,1,15.93,5h.5a.07.07,0,0,1,.07.07l0,2.35Zm2.36.16a1.67,1.67,0,0,1-1.71-1.88,1.72,1.72,0,1,1,3.42,0A1.67,1.67,0,0,1,18.86,10Zm5.22-.09H23.6a.08.08,0,0,1-.07-.07V9.36a1.41,1.41,0,0,1-1.26.63c-.69,0-1.23-.35-1.23-1,0-.81.63-1,1.35-1.15s1.12-.17,1.12-.53-.31-.57-.84-.57-.84.22-.88.62a.08.08,0,0,1-.08.07h-.48s-.07,0-.06-.07c.05-.73.6-1.14,1.49-1.14s1.46.45,1.46,1.22V8.51l0,1.33A.08.08,0,0,1,24.08,9.91ZM28,9.84a.07.07,0,0,1-.07.07h-.51a.07.07,0,0,1-.07-.07v-.5a1.26,1.26,0,0,1-1.14.64c-1,0-1.5-.81-1.5-1.86s.56-1.88,1.51-1.88a1.26,1.26,0,0,1,1.13.6V5.08A.07.07,0,0,1,27.43,5h.51a.07.07,0,0,1,.07.07L28,7.2Z"
            />
            <path
              className="filled"
              d="M26.4,6.69c-.69,0-1,.57-1,1.38s.33,1.45,1,1.45,1-.46,1-1.38S27,6.7,26.4,6.69Z"
            />
            <path
              className="outlined"
              d="M9.43,10A1.69,1.69,0,0,1,7.61,8.06V6.53l0-1.45A.08.08,0,0,1,7.66,5h.55a.07.07,0,0,1,.07.07l0,1.44V8.06c0,.83.34,1.39,1.17,1.39s1.2-.56,1.2-1.39V6.53l0-1.45A.07.07,0,0,1,10.68,5h.5a.08.08,0,0,1,.07.07l0,1.45V8.06C11.23,9.29,10.64,10,9.43,10Z"
            />
            <path
              className="outlined"
              d="M13.72,10a1.26,1.26,0,0,1-1.13-.6V11a.07.07,0,0,1-.07.07H12A.07.07,0,0,1,12,11l0-2.69,0-2A.07.07,0,0,1,12,6.31h.51a.08.08,0,0,1,.07.07l0,.5a1.26,1.26,0,0,1,1.15-.64c1,0,1.49.81,1.49,1.86S14.67,10,13.72,10Zm-.16-.47c.69,0,1-.56,1-1.36s-.32-1.44-1-1.44-1,.46-1,1.37S12.92,9.51,13.56,9.51Z"
            />
            <path
              className="outlined"
              d="M15.93,9.91a.07.07,0,0,1-.07-.07l0-2.41,0-2.35A.07.07,0,0,1,15.93,5h.5a.07.07,0,0,1,.07.07l0,2.35,0,2.41a.07.07,0,0,1-.07.07Z"
            />
            <path
              className="outlined"
              d="M18.86,10a1.67,1.67,0,0,1-1.71-1.88,1.72,1.72,0,1,1,3.42,0A1.67,1.67,0,0,1,18.86,10Zm0-.47c.71,0,1.05-.51,1.05-1.41s-.35-1.43-1.05-1.43-1.06.53-1.06,1.43S18.15,9.53,18.86,9.53Z"
            />
            <path
              className="outlined"
              d="M22.28,10c-.69,0-1.23-.35-1.23-1,0-.81.63-1,1.35-1.15s1.12-.17,1.12-.53-.31-.57-.84-.57-.84.22-.88.62a.08.08,0,0,1-.08.07h-.48s-.07,0-.06-.07c.05-.73.6-1.14,1.49-1.14s1.46.45,1.46,1.22V8.51l0,1.33a.08.08,0,0,1-.07.07H23.6a.08.08,0,0,1-.07-.07V9.36A1.41,1.41,0,0,1,22.28,10Zm.14-.48a1,1,0,0,0,1.1-.9V8a2.9,2.9,0,0,1-1,.24c-.48.08-.82.23-.82.67S22,9.52,22.42,9.51Z"
            />
            <path
              className="outlined"
              d="M27.43,9.91a.07.07,0,0,1-.07-.07v-.5a1.26,1.26,0,0,1-1.14.64c-1,0-1.5-.81-1.5-1.86s.56-1.88,1.51-1.88a1.26,1.26,0,0,1,1.13.6V5.08A.07.07,0,0,1,27.43,5h.51a.07.07,0,0,1,.07.07L28,7.2l0,2.64a.07.07,0,0,1-.07.07Zm-1-.39c.61,0,1-.46,1-1.38S27,6.7,26.4,6.69s-1,.57-1,1.38S25.71,9.52,26.39,9.52Z"
            />
            <path
              className="outlined"
              d="M28.43,15.27H7.63A7.64,7.64,0,0,1,7.63,0h20.8a7.64,7.64,0,1,1,0,15.27ZM7.63,1a6.64,6.64,0,0,0,0,13.27h20.8A6.64,6.64,0,1,0,28.43,1Z"
            />
          </svg>
        </button>
      </div>
    );
  }
);

function loadFile(file: File): Promise<string> {
  return new Promise((res, rej) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => res(reader.result as string), false);
  });
}
