import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import SimpleKeyboard from "react-simple-keyboard";
import firebase from "firebase";
import { isNumber, throttle, sample, debounce } from "lodash";
import { useSpring, animated } from "react-spring";
import useMeasure from "react-use-measure";

import "firebase/firestore";

import "react-simple-keyboard/build/css/index.css";
import "./Keyboard.scss";
import { FIREBASE_CONFIG } from "./firebaseConfig";

const IDLE_RELOAD_TIMEOUT = 60 * 60 * 1000;

const Keyboard: React.FC = () => {
  let { screen } = useParams();
  let [sizeRef, { width, height }] = useMeasure();
  let [doc, setDoc] = useState<any>();
  let [message, setMessage] = useState("");
  let [annihilation, setAnnihilation] = useState(0.33);
  let keyboard = useRef<any>();

  let db = useMemo(() => {
    firebase.initializeApp(FIREBASE_CONFIG);
    return firebase.firestore();
  }, []);

  useEffect(() => {
    if (screen && db) {
      let doc = db.collection("messages").doc(screen || "default");

      let loadDoc = () => {
        doc
          .get()
          .then((docSnapshot) => {
            if (docSnapshot.exists) {
              setDoc(doc);
            } else {
              doc.set({}).then(() => setDoc(doc));
            }
          })
          .catch((err) => {
            console.log("failed loading the doc", err);
            setTimeout(loadDoc, 5000);
          });
      };
      loadDoc();
    }
  }, [screen, db]);

  useEffect(() => {
    if (!doc) return;
    doc.get().then((docSnapshot: any) => {
      let data = docSnapshot.data();
      if (data) {
        setMessage(data.value || "");
        setAnnihilation(isNumber(data.annihilation) ? data.annihilation : 0.5);
        if (keyboard.current) {
          keyboard.current.setInput(data.value || "");
        }
      }
    });
  }, [doc]);

  let [bgSpring, setBgSpring] = useSpring(() => ({
    backgroundColor: "#ffffff",
  }));

  useEffect(() => {
    let unsubscribe = db
      .collection("colors")
      .doc(screen || "default")
      .onSnapshot((docSnapshot: any) => {
        let data = docSnapshot.data();
        if (data && data.backgroundColor) {
          console.log("recv", data.backgroundColor);
          setBgSpring({ backgroundColor: data.backgroundColor });
        }
      });
    return () => {
      unsubscribe();
    };
  }, [screen]);

  let setKeyboardRef = (k: any) => {
    k.setInput(message);
    keyboard.current = k;
  };

  let updateMessage = useCallback(
    throttle((newMsg: string) => {
      doc.update({
        value: newMsg,
      });
      setMessage(newMsg);
    }, 50),
    [doc]
  );

  let updateAnnihilation = useCallback(
    throttle((annihilation: number) => {
      doc.update({
        annihilation,
      });
      setAnnihilation(annihilation);
    }, 50),
    [doc]
  );

  let debounceReset = useMemo(
    () => debounce(() => window.location.reload(), IDLE_RELOAD_TIMEOUT),
    []
  );

  let getRemainingCharacterCount = useCallback(() => {
    return 10 - message.length;
  }, [message]);

  let widthBound = width / 1366;
  let heightBound = height / 1024;
  let scale = Math.min(1, Math.min(widthBound, heightBound));
  let scaledWidth = 1366 * scale;
  let translateX = Math.max(0, (width - scaledWidth) / 2);
  let scaledHeight = 1024 * scale;
  let translateY = Math.max(0, (height - scaledHeight) / 2);

  return (
    <div className="keyboardSizeWrapper" ref={sizeRef}>
      <animated.div
        className="keyboard"
        style={{
          ...bgSpring,
          transform: `scale(${scale})`,
          left: translateX,
          top: translateY,
        }}
      >
        <div className="inputs">
          <div className="label">
            <div className="labelMinus">
              <div className="labelIconLine"></div>
            </div>
            <div className="labelFruiting">Fruiting</div>
            <div className="labelPlus">
              <div className="labelIconLine"></div>
              <div className="labelIconLine"></div>
            </div>
          </div>
          <input
            type="range"
            min={0}
            max={1}
            step={0.01}
            value={annihilation}
            onChange={(evt) => {
              debounceReset();
              updateAnnihilation(+evt.currentTarget.value);
            }}
          />
        </div>
        <div className="message">
          <div className="remainingCharacterCount">
            {getRemainingCharacterCount()}/10
          </div>
          <div className="currentMessage">{message}</div>
          <div className="pseudoCursor"></div>
        </div>
        {doc && (
          <SimpleKeyboard
            keyboardRef={setKeyboardRef}
            onChange={(newMsg) => {
              debounceReset();
              updateMessage(newMsg);
            }}
            display={{
              "{bksp}": "DELETE",
              "{space}": "SPACE",
            }}
            layout={{
              default: [
                "Q W E R T Y U I O P {bksp}",
                " A S D F G H J K L  ",
                " Z X C V B N M   ",
                "{space}",
              ],
            }}
            maxLength={10}
          />
        )}
      </animated.div>
    </div>
  );
};

export default Keyboard;
