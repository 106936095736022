import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Canvas } from "react-three-fiber";
import { Vector3 } from "three";
import { diffChars } from "diff";
import { pullAt, isNumber, sample } from "lodash";

import * as firebase from "firebase/app";
import "firebase/firestore";
import { useSpring, animated } from "react-spring";

import "./Exhibition.scss";

import { MyceliumIsosurface } from "./MyceliumIsosurface";

import { CameraControls } from "./CameraControls";
import {
  ExhibitionGlyph,
  typesetGlyphs,
  calculateDisplacementNoiseScale,
} from "./ExhibitionGlyph";
import { useParams } from "react-router-dom";
import { SCAFFOLDS, loadScaffolds } from "./alphabet";
import { FIREBASE_CONFIG } from "./firebaseConfig";
import { Mycelium } from "./Mycelium";
import { getEnvMap } from "./envmapLoader";
import { Color, MUSHROOM_COLORS, BACKGROUND_COLORS } from "./core";

const IDLE_RESET_TIMEOUT = 2 * 60 * 1000;
const IDLE_RELOAD_TIMEOUT = 60 * 60000;

const IDLE_WORDS = [
  "HYPHA",
  "MUSHROOM",
  "FUNGI",
  "FUNGAL",
  "FUNGUS",
  "MYCEL",
  "MYCELIA",
  "MYCELIUM",
  "SPORE",
  "COLONY",
  "HYPHAE",
  "SHIRO",
  "ENZYME",
  "POLYMER",
  "MONOMER",
  "YEAST",
  "MOLD",
  "GILL",
  "CAP",
  "CUP",
  "STALK",
  "SIENI",
  "SEEN",
  "CHAMPIGNON",
  "PILZ",
  "FUNGO",
  "SVAMP",
  "MYKES",
  "FUNGHI",
  "MADARCH",
  "BUACHAIR",
];
const OPENING_HOUR = 9;
const CLOSING_HOUR = 21;

const AnimatedCameraControls = animated(CameraControls);

const Exhibition: React.FC = () => {
  let { screen } = useParams();
  let [loaded, setLoaded] = useState(false);
  let [genCnt, setGenCnt] = useState(0);
  let [doc, setDoc] = useState<any>();
  let message = useRef("");
  let [annihilation, setAnnihilation] = useState(0.5);
  let [stateCnt, setStateCnt] = useState(0);
  let glyphs = useRef<(ExhibitionGlyph | "space")[]>([]);
  let glyphsTypeset = useRef<{ [glyphd: string]: Vector3 }>({});
  let [mushroomColors, setMushroomColors] = useState(sample(MUSHROOM_COLORS)!);
  let displacementNoiseScale = useRef(calculateDisplacementNoiseScale());

  useEffect(() => {
    loadScaffolds().then(() => setLoaded(true));
  }, []);

  let onCanvasCreated = useCallback((gl: THREE.WebGLRenderer) => {
    getEnvMap(gl);
  }, []);

  useEffect(() => {
    let reloadTimer = setTimeout(() => {
      window.location.reload();
    }, IDLE_RELOAD_TIMEOUT);
    return () => {
      clearTimeout(reloadTimer);
    };
  }, [stateCnt]);

  let [bgSpring, setBgSpring] = useSpring(() => ({
    backgroundColor: sample(BACKGROUND_COLORS),
  }));
  let [cameraZSpring, setCameraZSpring] = useSpring(() => ({
    value: 2000,
    config: { mass: 1, tension: 280, friction: 120 },
  }));
  let db = useMemo(() => {
    firebase.initializeApp(FIREBASE_CONFIG);
    return firebase.firestore();
  }, []);

  let cycleParamsForNewMessage = useCallback(() => {
    let newBgColor = sample(BACKGROUND_COLORS)!;
    setBgSpring({ backgroundColor: newBgColor });
    setMushroomColors(sample(MUSHROOM_COLORS)!);
    db.collection("colors")
      .doc(screen || "default")
      .set({
        backgroundColor: newBgColor,
      });
    displacementNoiseScale.current = calculateDisplacementNoiseScale();
  }, [screen]);

  let updateMessage = useCallback(
    (newMessage: string) => {
      let newMsg = newMessage.toUpperCase();
      if (message.current.length === 0 && newMessage.length !== 0) {
        cycleParamsForNewMessage();
      }
      if (newMsg.length > 10) {
        newMsg = newMsg.substring(0, 10);
      }
      let diff = diffChars(message.current, newMsg);
      let idx = 0;
      for (let part of diff) {
        if (part.added) {
          for (let chr of part.value) {
            if (chr === " ") {
              glyphs.current.splice(idx, 0, "space");
              idx++;
            } else if (SCAFFOLDS[chr]) {
              let glyph = new ExhibitionGlyph({
                chr,
                annihilation,
                substrateScaffold: SCAFFOLDS[chr][0],
                inhibitorScaffold: SCAFFOLDS[chr][1],
                displacementNoiseScale: displacementNoiseScale.current,
                glSupportsDerivatives: true,
              });
              glyph.addListener("isosurfaceUpdate", () =>
                setStateCnt((c) => c + 1)
              );
              glyphs.current.splice(idx, 0, glyph);
              idx++;
            }
          }
        } else if (part.removed) {
          for (let chr of part.value) {
            if (chr === " ") {
              pullAt(glyphs.current, idx);
            } else if (SCAFFOLDS[chr]) {
              let glyph = glyphs.current[idx] as ExhibitionGlyph;
              glyph.dispose();
              pullAt(glyphs.current, idx);
            }
          }
        } else {
          for (let chr of part.value) {
            if (SCAFFOLDS[chr] || chr === " ") {
              idx++;
            }
          }
        }
      }
      glyphsTypeset.current = typesetGlyphs(glyphs.current);
      message.current = newMsg;
      setStateCnt((c) => c + 1);
      setCameraZSpring({ value: 1000 + glyphs.current.length * 150 });
    },
    [annihilation, cycleParamsForNewMessage]
  );

  let updateAnnihilation = useCallback((newAnnihilation: number) => {
    for (let glyph of glyphs.current) {
      if (glyph !== "space") {
        glyph.updateAnnihilation(newAnnihilation);
      }
    }
    setAnnihilation(newAnnihilation);
  }, []);

  useEffect(() => {
    // After some inactivity, re-set values
    if (!loaded) return;
    console.log("scheduling reset");
    let reset = () => {
      let isGalleryOpen =
        new Date().getHours() >= OPENING_HOUR &&
        new Date().getHours() < CLOSING_HOUR;
      let newMsg = isGalleryOpen ? sample(IDLE_WORDS)! : "";
      console.log("trigger reset", newMsg);
      if (newMsg !== message.current) {
        updateMessage("");
        updateMessage(newMsg);
      }
      setGenCnt(genCnt + 1);
    };
    let resetTimer = setTimeout(
      reset,
      genCnt === 0 ? 5000 : IDLE_RESET_TIMEOUT
    );
    return () => {
      clearTimeout(resetTimer);
    };
  }, [loaded, genCnt, updateMessage, updateAnnihilation]);

  useEffect(() => {
    if (screen && db) {
      let doc = db.collection("messages").doc(screen || "default");

      let loadDoc = () => {
        doc
          .get()
          .then((docSnapshot) => {
            if (docSnapshot.exists) {
              setDoc(doc);
            } else {
              doc.set({}).then(() => setDoc(doc));
            }
          })
          .catch((err) => {
            console.error("failed loading the doc", err);
            setTimeout(loadDoc, 5000);
          });
      };
      loadDoc();
    }
  }, [screen, db]);

  useEffect(() => {
    if (!loaded || !doc) return;

    let unsubscribe = doc.onSnapshot((docSnapshot: any) => {
      let data = docSnapshot.data();
      if (data) {
        updateMessage(data.value || "");
        updateAnnihilation(
          isNumber(data.annihilation) ? data.annihilation : 0.5
        );
      }
    });
    return () => {
      unsubscribe();
    };
  }, [updateMessage, doc, loaded]);

  let renderedGlyphs = glyphs.current.filter(
    (g) => g !== "space" && g.controls
  ) as ExhibitionGlyph[];

  return (
    <animated.div className="exhibition" style={bgSpring}>
      <Canvas
        camera={{ fov: 20, near: 200, far: 20000 }}
        onCreated={({ gl }) => onCanvasCreated(gl)}
      >
        <ambientLight intensity={0.5} />
        <AnimatedCameraControls z={cameraZSpring.value as any} />
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh />
          {renderedGlyphs.map((glyph, idx) => {
            let glyphSet = glyphsTypeset.current[glyph.id];
            return (
              <React.Fragment key={glyph.id}>
                {/*<Scaffold
                  translate={glyphSet}
                  geometry={glyph.scaffold.substrateScaffold}
                  inhibitorGeometry={glyph.scaffold.inhibitorScaffold}
                  scale={10.5}
                  mode={"band"}
                  isVisible={true}
                  onAddGrowth={pt => {}}
                />*/}
                <MyceliumIsosurface
                  translate={glyphSet}
                  stillGrowing={glyph.isosurfaceData.stillGrowing}
                  useComputedNormals={glyph.isosurfaceData.subdivided}
                  controls={glyph.controls!}
                  vertexAttribute={glyph.isosurfaceData.vertexAttribute}
                  faceAttribute={glyph.isosurfaceData.faceAttribute}
                  vertexNormalAttribute={
                    glyph.isosurfaceData.vertexNormalAttribute
                  }
                  glyph={glyph}
                  colors={mushroomColors}
                />
                {glyph.myceliumSections && (
                  <Mycelium
                    translate={glyphSet}
                    sections={glyph.myceliumSections}
                  />
                )}
              </React.Fragment>
            );
          })}
        </group>
      </Canvas>
      {/*
        <input
          type="text"
          style={{ position: "fixed", left: 10, top: 10 }}
          value={message}
          onChange={evt => updateMessage(evt.currentTarget.value)}
        />
      */}
    </animated.div>
  );
};

export default Exhibition;
