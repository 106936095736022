import React, { useCallback, useState, useEffect } from "react";
import { Canvas } from "react-three-fiber";
import { random } from "lodash";

import "./Authoring.scss";

import { Controls } from "./Controls";
import { Mycelium } from "./Mycelium";
import { MyceliumIsosurface } from "./MyceliumIsosurface";

import { installOBJLoader } from "./three/OBJLoader";

import defaultSubstrateModelUrl from "./assets/P_BASE.obj";
import defaultInhibitorModelUrl from "./assets/P_CO.obj";

import { Geometry, Vector3, BufferAttribute } from "three";
import { Scaffold } from "./Scaffold";
import { CameraControls } from "./CameraControls";
import { ControlOptions } from "./core";
import { downloadIsosurface } from "./downloadMeshSequence";
import { collectScaffoldVertices, loadScaffoldGeometry } from "./scaffolds";

const THREE = require("three");

const Worker = require("./worker");

const SCAFFOLD_INIT_SCALE = 15;

installOBJLoader(THREE);

const Authoring: React.FC = () => {
  let [worker, setWorker] = useState<any>();
  let [isShowingScaffold, setIsShowingScaffold] = useState(true);
  let [isShowingMycelium, setIsShowingMycelium] = useState(true);
  let [isShowingIsosurface, setIsShowingIsosurface] = useState(true);
  let [isPlaying, setIsPlaying] = useState(false);
  let [age, setAge] = useState(0);
  let [sections, setSections] = useState<Float32Array>(new Float32Array(0));
  let [isosurfaceData, setIsosurfaceData] = useState({
    vertexAttribute: new BufferAttribute(new Float32Array(0), 3),
    faceAttribute: new BufferAttribute(new Uint32Array(0), 3),
    vertexNormalAttribute: new BufferAttribute(new Float32Array(0), 3),
  });
  let [controls, setControls] = useState<ControlOptions>();
  let [substrateScaffoldUrl, setSubstrateScaffoldUrl] = useState(
    defaultSubstrateModelUrl
  );
  let [inhibitorScaffoldUrl, setInhibitorScaffoldUrl] = useState(
    defaultInhibitorModelUrl
  );
  let [substrateScaffold, setSubstrateScaffold] = useState<Geometry>();
  let [substrateScaffoldCenter, setSubstrateScaffoldCenter] = useState(
    new Vector3(0, 0, 0)
  );
  let [inhibitorScaffold, setInhibitorScaffold] = useState<Geometry>();

  useEffect(() => {
    if (!substrateScaffoldUrl) return;
    loadScaffoldGeometry({
      url: substrateScaffoldUrl,
      subtract: "self",
      initScale: SCAFFOLD_INIT_SCALE,
    }).then(([geom, center]) => {
      if (substrateScaffold) {
        substrateScaffold.dispose();
      }
      setSubstrateScaffold(geom);
      setSubstrateScaffoldCenter(center);
    });
  }, [substrateScaffoldUrl]);
  useEffect(() => {
    if (!inhibitorScaffoldUrl) return;
    loadScaffoldGeometry({
      url: inhibitorScaffoldUrl,
      subtract: substrateScaffoldCenter,
      initScale: SCAFFOLD_INIT_SCALE,
    }).then(([geom]) => {
      if (inhibitorScaffold) {
        inhibitorScaffold.dispose();
      }
      setInhibitorScaffold(geom);
    });
  }, [inhibitorScaffoldUrl, substrateScaffoldCenter]);

  useEffect(() => {
    let worker = new Worker();
    worker.postMessage({
      type: "init",
    });
    worker.postMessage({
      type: "toggleIsosurface",
      on: true,
    });
    worker.addEventListener("message", (event: MessageEvent) => {
      switch (event.data.type) {
        case "initControls":
          setControls(event.data.initialControls);
          break;
        case "updateSections":
          setAge(event.data.age);
          setSections(new Float32Array(event.data.sections));
          if (event.data.isosurfaceVertices) {
            setIsosurfaceData({
              vertexAttribute: new BufferAttribute(
                new Float32Array(event.data.isosurfaceVertices),
                3
              ),
              faceAttribute: new BufferAttribute(
                new Uint32Array(event.data.isosurfaceFaces),
                3
              ),
              vertexNormalAttribute: new BufferAttribute(
                new Float32Array(event.data.vertexNormals || 0),
                3
              ),
            });
          }
          break;
        case "updateIsosurface":
          setIsosurfaceData({
            vertexAttribute: new BufferAttribute(
              new Float32Array(event.data.isosurfaceVertices),
              3
            ),
            faceAttribute: new BufferAttribute(
              new Uint32Array(event.data.isosurfaceFaces),
              3
            ),
            vertexNormalAttribute: new BufferAttribute(
              new Float32Array(event.data.vertexNormals || 0),
              3
            ),
          });
          break;
      }
    });
    setWorker(worker);
  }, []);

  useEffect(() => {
    if ((!substrateScaffold && !inhibitorScaffold) || !worker || !controls)
      return;

    let substrateScaffoldVertices: Float32Array | undefined = undefined;
    let substrateScaffoldFaces: Uint32Array | undefined = undefined;
    let inhibitorScaffoldVertices: Float32Array | undefined = undefined;
    let inhibitorScaffoldFaces: Uint32Array | undefined = undefined;

    if (substrateScaffold) {
      let collected = collectScaffoldVertices(
        substrateScaffold,
        controls.scaffoldScale
      );
      substrateScaffoldVertices = collected[0];
      substrateScaffoldFaces = collected[1];
    }
    if (inhibitorScaffold) {
      let collected = collectScaffoldVertices(
        inhibitorScaffold,
        controls.scaffoldScale
      );
      inhibitorScaffoldVertices = collected[0];
      inhibitorScaffoldFaces = collected[1];
    }

    worker.postMessage(
      {
        type: "setScaffold",
        scaffoldVertices:
          substrateScaffoldVertices && substrateScaffoldVertices.buffer,
        scaffoldFaces: substrateScaffoldFaces && substrateScaffoldFaces.buffer,
        inhibitorScaffoldVertices:
          inhibitorScaffoldVertices && inhibitorScaffoldVertices.buffer,
        inhibitorScaffoldFaces:
          inhibitorScaffoldFaces && inhibitorScaffoldFaces.buffer,
      },
      [
        substrateScaffoldVertices && substrateScaffoldVertices.buffer,
        substrateScaffoldFaces && substrateScaffoldFaces.buffer,
        inhibitorScaffoldVertices && inhibitorScaffoldVertices.buffer,
        inhibitorScaffoldFaces && inhibitorScaffoldFaces.buffer,
      ].filter((b) => !!b)
    );
  }, [substrateScaffold, inhibitorScaffold, worker, controls]);

  let onControlsChange = useCallback(
    (newControls: ControlOptions) => {
      setControls({ ...newControls });
      worker.postMessage({ type: "setControls", controls: newControls });
    },
    [worker]
  );

  let step = useCallback(() => {
    if (!worker) return;
    worker.postMessage({ type: "step" });
  }, [worker]);

  let togglePlay = useCallback(
    (play: boolean) => {
      if (play) {
        worker.postMessage({ type: "start" });
      } else {
        worker.postMessage({ type: "stop" });
      }
      setIsPlaying(play);
    },
    [worker]
  );

  return (
    <div
      className="authoring"
      style={{
        backgroundColor:
          controls && `rgba(${controls.backgroundColor.join(",")})`,
      }}
    >
      {controls && (
        <Controls
          controls={controls}
          age={age}
          isShowingScaffold={isShowingScaffold}
          isShowingMycelium={isShowingMycelium}
          isShowingIsosurface={isShowingIsosurface}
          isPlaying={isPlaying}
          onControlsChange={onControlsChange}
          onStep={step}
          onToggleScaffold={() => setIsShowingScaffold(!isShowingScaffold)}
          onUpdateSubstrateScaffoldUrl={setSubstrateScaffoldUrl}
          onUpdateInhibitorScaffoldUrl={setInhibitorScaffoldUrl}
          onToggleMycelium={() => setIsShowingMycelium(!isShowingMycelium)}
          onToggleIsosurface={() => {
            worker.postMessage({
              type: "toggleIsosurface",
              on: !isShowingIsosurface,
            });
            setIsShowingIsosurface(!isShowingIsosurface);
          }}
          onTogglePlay={() => togglePlay(!isPlaying)}
          onUpdateMesh={() =>
            worker.postMessage({
              type: "calculateIsosurface",
            })
          }
          onDownloadMesh={() =>
            downloadIsosurface(
              isosurfaceData.vertexAttribute.array as Float32Array,
              isosurfaceData.faceAttribute.array as Uint32Array
            )
          }
        />
      )}
      <Canvas shadowMap camera={{ fov: 10, near: 2, far: 20000 }}>
        <pointLight castShadow position={[0, 0, 100]} intensity={0.2} />
        <pointLight castShadow position={[0, 0, -100]} intensity={0.2} />
        <ambientLight intensity={0.5} />
        <CameraControls z={8500} />
        <group rotation={[Math.PI / 2, 0, 0]}>
          {substrateScaffold && controls && (
            <Scaffold
              geometry={substrateScaffold}
              inhibitorGeometry={inhibitorScaffold}
              scale={controls.scaffoldScale}
              mode={controls.substrateMode}
              isVisible={isShowingScaffold}
              onAddGrowth={(pt) => {
                worker.postMessage({
                  type: "addSpore",
                  sporeOrigin: pt.toArray(),
                });
                togglePlay(true);
              }}
            />
          )}
          {isShowingMycelium && <Mycelium sections={sections} />}
          {isShowingIsosurface && controls && (
            <MyceliumIsosurface
              translate={new Vector3(0, 0, 0)}
              stillGrowing={false}
              useComputedNormals={true}
              controls={controls}
              vertexAttribute={isosurfaceData.vertexAttribute}
              faceAttribute={isosurfaceData.faceAttribute}
              vertexNormalAttribute={isosurfaceData.vertexNormalAttribute}
            />
          )}
        </group>
      </Canvas>
    </div>
  );
};

export default Authoring;
