import * as THREE from 'three';

export function exportToObj(positions: [number, number, number][], cells: [number, number, number][]) {

    let output = '';

    let indexVertex = 0;
    let indexNormals = 0;

    var vertex = new THREE.Vector3();
    var normal = new THREE.Vector3();

    var i, j, l, m, face = [];


    var nbVertex = 0;
    var nbNormals = 0;

    let dGeometry = new THREE.Geometry();
    for (let i = 0; i < cells.length; i++) {
        let face = new THREE.Face3(cells[i][0], cells[i][1], cells[i][2]);
        dGeometry.faces.push(face);
    }
    for (let i = 0; i < positions.length; i++) {
        let v = new THREE.Vector3().fromArray(positions[i]);
        dGeometry.vertices.push(v);
    }
    dGeometry.computeVertexNormals();

    let geometry = new THREE.BufferGeometry().fromGeometry(dGeometry);


    // shortcuts
    var vertices = geometry.getAttribute('position');
    var normals = geometry.getAttribute('normal');
    var indices = geometry.getIndex();

    // name of the mesh object
    output += 'o mushroom\n';

    // vertices

    if (vertices !== undefined) {

        for (i = 0, l = vertices.count; i < l; i++ , nbVertex++) {

            vertex.x = vertices.getX(i);
            vertex.y = vertices.getY(i);
            vertex.z = vertices.getZ(i);

            // transform the vertex to export format
            output += 'v ' + vertex.x + ' ' + vertex.y + ' ' + vertex.z + '\n';

        }

    }

    // normals

    if (normals !== undefined) {

        for (i = 0, l = normals.count; i < l; i++ , nbNormals++) {

            normal.x = normals.getX(i);
            normal.y = normals.getY(i);
            normal.z = normals.getZ(i);

            // transform the normal to export format
            output += 'vn ' + normal.x + ' ' + normal.y + ' ' + normal.z + '\n';

        }

    }

    // faces

    if (indices !== null) {

        for (i = 0, l = indices.count; i < l; i += 3) {

            for (m = 0; m < 3; m++) {

                j = indices.getX(i + m) + 1;

                face[m] = (indexVertex + j) + '/' + '/' + (indexNormals + j);

            }

            // transform the face to export format
            output += 'f ' + face.join(' ') + "\n";

        }

    } else {

        for (i = 0, l = vertices.count; i < l; i += 3) {

            for (m = 0; m < 3; m++) {

                j = i + m + 1;

                face[m] = (indexVertex + j) + '/' + '/' + (indexNormals + j);

            }

            // transform the face to export format
            output += 'f ' + face.join(' ') + "\n";

        }

    }


    // update index
    indexVertex += nbVertex;
    indexNormals += nbNormals;

    return output;

}
