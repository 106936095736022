import React, { useMemo, useState, useEffect } from "react";
import { useFrame, useThree } from "react-three-fiber";
import _CameraControls from "camera-controls";
import * as THREE from "three";

_CameraControls.install({ THREE });

interface CameraControlsProps {
  z: number;
  oscillate?: boolean;
}
export const CameraControls: React.FC<CameraControlsProps> = ({
  z,
  oscillate,
}) => {
  let { camera, gl } = useThree();
  let [rotating, setRotating] = useState(true);

  let cameraControls = useMemo(() => {
    let controls = new _CameraControls(camera, gl.domElement);
    controls.addEventListener("controlstart", () => {
      setRotating(false);
    });
    return controls;
  }, [camera, gl]);

  useEffect(() => {
    cameraControls && cameraControls.setLookAt(0, 0, z, 0, 0, 0, false);
  }, [cameraControls, z]);

  useFrame((state, delta) => {
    if (rotating || oscillate) {
      if (oscillate) {
        cameraControls.setLookAt(
          0,
          0,
          z * (1 + Math.sin(Date.now() / 3000) * 0.2),
          0,
          0,
          0,
          false
        );
      } else {
        let now = Date.now();
        let angle = Math.sin(now / 10000) * 0.15;
        let distanceDelta = Math.sin(now / 15000) * 0.25;
        let distance = z * (1 + distanceDelta);
        let height = Math.sin(now / 18000) * 500;
        cameraControls.setLookAt(
          distance * Math.sin(angle),
          height,
          distance * Math.cos(angle),
          0,
          0,
          0,
          false
        );
      }
    }
    cameraControls.update(delta);
  });

  return <></>;
};
