import React, { useCallback, useRef, useState } from "react";
import { Geometry, Color, DoubleSide, Mesh, Vector3, Matrix4 } from "three";
import { PointerEvent, useThree } from "react-three-fiber";

interface ScaffoldProps {
  geometry: Geometry;
  inhibitorGeometry?: Geometry;
  scale: number;
  translate?: Vector3;
  mode: "band" | "infinite";
  isVisible: boolean;
  onAddGrowth?: (pt: Vector3) => void;
}
export const Scaffold: React.FC<ScaffoldProps> = ({
  geometry,
  inhibitorGeometry,
  scale,
  translate,
  mode,
  isVisible,
  onAddGrowth,
}) => {
  let meshRef = useRef<Mesh>();
  let { raycaster } = useThree();

  let onMeshClick = useCallback(
    (e: any) => {
      if (!onAddGrowth) return;
      let mesh = meshRef.current as Mesh;
      raycaster.set(e.ray.origin, e.ray.direction);
      let intersections = raycaster.intersectObject(mesh);
      if (intersections.length === 2) {
        let from = intersections[0].point.clone();
        let to = intersections[1].point.clone();
        let v = to.clone().sub(from);
        let vUnit = v.clone().normalize();
        let vLength = v.length();
        let pt = from
          .clone()
          .add(vUnit.multiplyScalar(Math.random() * vLength))
          .applyAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);
        onAddGrowth(pt);
      }
    },
    [raycaster, onAddGrowth]
  );

  return (
    <>
      <mesh
        ref={meshRef}
        geometry={geometry}
        scale={[scale, scale, scale]}
        position={translate}
        onClick={onMeshClick}
        renderOrder={1}
      >
        <meshBasicMaterial
          attach="material"
          wireframe={true}
          color={new Color(0x000000)}
          transparent={true}
          side={DoubleSide}
          opacity={isVisible ? 0.075 : 0}
        />
      </mesh>
      {mode === "infinite" && (
        <mesh
          geometry={geometry}
          scale={[scale, scale, scale]}
          position={translate}
          renderOrder={1}
        >
          <meshBasicMaterial
            attach="material"
            wireframe={false}
            color={new Color(0x000000)}
            transparent={true}
            opacity={isVisible ? 0.075 : 0}
          />
        </mesh>
      )}
      {inhibitorGeometry && (
        <mesh
          geometry={inhibitorGeometry}
          scale={[scale, scale, scale]}
          position={translate}
          renderOrder={1}
        >
          <meshBasicMaterial
            attach="material"
            wireframe={true}
            color={new Color(0xff0000)}
            side={DoubleSide}
            transparent={true}
            opacity={isVisible ? 0.075 : 0}
          />
        </mesh>
      )}
    </>
  );
};
