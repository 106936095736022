import localforage from 'localforage';
import JSZip from 'jszip';
import { sortBy } from 'lodash';
import { downloadBlob } from './util';
import { exportToObj } from './objExport';

export async function downloadMeshSequence() {
    let seqCount = (await localforage.getItem('meshSequenceCount')) as number;
    let keys = await localforage.keys();
    let zip = new JSZip();
    for (let i = 0; i < seqCount; i++) {
        let objKeys = sortBy(keys.filter(k => k.startsWith(`meshSequence${i}`)));
        let objString = '';
        for (let objKey of objKeys) {
            console.log(objKey);
            objString += await localforage.getItem(objKey) as string;
        }
        zip.file(`step${i}.obj`, objString);
    }
    let zipBlob = await zip.generateAsync({ type: 'blob' });
    downloadBlob(zipBlob, 'mushroom_mesh_sequence.zip');
}

export function downloadIsosurface(positionsRaw: Float32Array, cellsRaw: Uint32Array) {
    let positions: [number, number, number][] = [];
    for (let i = 0; i < positionsRaw.length; i += 3) {
        positions.push([positionsRaw[i], positionsRaw[i + 1], positionsRaw[i + 2]]);
    }
    let cells: [number, number, number][] = [];
    for (let i = 0; i < cellsRaw.length; i += 3) {
        cells.push([cellsRaw[i], cellsRaw[i + 1], cellsRaw[i + 2]]);
    }
    let obj = new Blob([exportToObj(positions, cells)], { type: 'text/plain' });
    downloadBlob(obj, 'mushroom.obj');
}