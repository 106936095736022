export type Color = [number, number, number];
export type ColorWithAlpha = [number, number, number, number];

export interface ControlOptions {
    scaffoldScale: number;
    calculateIsosurfaceEvery: number;
    isosurfaceRadius: [number, number];
    isosurfaceNeighbourhood: number;
    isosurfaceSubdivision: number;
    substrateStrength: number;
    escapeProbability: number;
    substrateMode: 'band' | 'infinite';
    randomComponent: number;
    neighbourhoodRadius: number;
    branchingDensity: number;
    growingDensity: number;
    maxGrowingAge: number;
    isosurfaceExpansionAge: number;
    maxTotalGrowingAge: number;
    maxBranchingAge: number;
    fieldHypothesisOn: boolean;
    autotropismOn: boolean;
    autotropismImpact: number;
    tropismInertia: number;
    sensorTurn1On: boolean;
    gravitropismOn: boolean;
    gravitropismAngle: number;
    gravitropismImpact: number;
    parallelTropismOn: boolean;
    positiveTropismOn: boolean;
    horizontalPlaneTropismOn: boolean;
    surfaceGrowthAge: number;
    displacementNoiseScale: [number, number, number],
    displacementAmplitude: number;
    displacementBloom: number;
    displacementDelta: number;
    displacementDirection: [number, number, number],
    displacementMesoScale: [number, number, number],
    bumpNoiseScale: [number, number, number];
    bumpNoiseAmplitude: number;
    backgroundColor: ColorWithAlpha;
    colors: [Color, number][];
}

export const BACKGROUND_COLORS = [
    "#FDFE6E",
    "#FF7336",
    "#BC58CB",
    "#C0C899",
    "#A38384"
];

export type ColorScheme = [Color, number][];

export const MUSHROOM_COLORS = [
    [
        [[240, 240, 223], 0.0],
        [[240, 240, 223], 0.15],
        [[255, 115, 54], 0.4],
        [[240, 240, 223], 0.75],
        [[255, 115, 54], 1.0]
    ],
    [
        [[240, 240, 223], 0.0],
        [[240, 240, 223], 0.1],
        [[253, 254, 110], 0.15],
        [[188, 88, 203], 0.6],
        [[255, 115, 54], 1.0]
    ],
    [
        [[163, 131, 132], 0],
        [[192, 200, 153], 0.2],
        [[240, 240, 223], 0.4],
        [[253, 254, 110], 0.6],
        [[192, 200, 153], 0.8]
    ],
    [
        [[240, 240, 223], 0],
        [[163, 131, 132], 0.4],
        [[255, 115, 54], 0.7],
        [[188, 88, 203], 0.9],
        [[188, 88, 203], 1.0]
    ]
] as ColorScheme[];