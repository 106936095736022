import React, { useRef, useEffect } from "react";

import { useMemo } from "react";

import { LineSegments, Geometry, Vector3 } from "three";

interface MyceliumProps {
  sections: Float32Array;
  translate?: Vector3;
}
export const Mycelium: React.FC<MyceliumProps> = React.memo(
  ({ sections, translate }) => {
    let meshRef = useRef<LineSegments>();

    let initVertices = useMemo(() => {
      let vert: Vector3[] = [];
      for (let i = 0; i < 50000; i++) {
        vert.push(new Vector3(0, 0, 0));
      }
      return vert;
    }, []);

    useEffect(() => {
      let seg = meshRef.current!;
      let geo = seg.geometry as Geometry;
      for (let i = 0; i < geo.vertices.length && i * 3 < sections.length; i++) {
        let vert = geo.vertices[i];
        vert.setX(sections[i * 3]);
        vert.setY(sections[i * 3 + 1]);
        vert.setZ(sections[i * 3 + 2]);
      }
      geo.verticesNeedUpdate = true;
    }, [sections]);

    return (
      <lineSegments ref={meshRef} renderOrder={1} position={translate}>
        <geometry attach="geometry" vertices={initVertices} />
        <lineBasicMaterial
          attach="material"
          color={0x79ff61}
          depthTest={false}
          depthWrite={false}
          transparent={true}
          opacity={1}
        />
      </lineSegments>
    );
  }
);
