import React, { useCallback } from "react";
import ColorPicker, { Panel as ColorPickerPanel } from "rc-color-picker";
import hexRgb from "hex-rgb";
import rgbHex from "rgb-hex";
import { take } from "lodash";

import "rc-color-picker/assets/index.css";
import "./App.scss";

import "./Controls.scss";
import { ControlOptions } from "./core";
import GradientBuilder from "./gradient/GradientBuilder";
import { downloadMeshSequence } from "./downloadMeshSequence";

interface ControlsProps {
  controls: ControlOptions;
  age: number;
  isPlaying: boolean;
  isShowingScaffold: boolean;
  isShowingMycelium: boolean;
  isShowingIsosurface: boolean;
  onControlsChange: (controls: ControlOptions) => void;
  onTogglePlay: () => void;
  onUpdateSubstrateScaffoldUrl: (newUrl: string) => void;
  onUpdateInhibitorScaffoldUrl: (newUrl: string) => void;
  onToggleScaffold: () => void;
  onToggleMycelium: () => void;
  onToggleIsosurface: () => void;
  onStep: () => void;
  onUpdateMesh: () => void;
  onDownloadMesh: () => void;
}
export const Controls: React.FC<ControlsProps> = ({
  controls,
  age,
  isPlaying,
  isShowingScaffold,
  isShowingMycelium,
  isShowingIsosurface,
  onControlsChange,
  onToggleScaffold,
  onUpdateSubstrateScaffoldUrl,
  onUpdateInhibitorScaffoldUrl,
  onToggleMycelium,
  onToggleIsosurface,
  onTogglePlay,
  onStep,
  onUpdateMesh,
  onDownloadMesh
}) => {
  let updateSubstrateScaffoldModel = useCallback(
    (evt: React.SyntheticEvent<HTMLInputElement>) => {
      loadFile(evt.currentTarget).then(onUpdateSubstrateScaffoldUrl);
    },
    [onUpdateSubstrateScaffoldUrl]
  );
  let updateInhibitorScaffoldModel = useCallback(
    (evt: React.SyntheticEvent<HTMLInputElement>) => {
      loadFile(evt.currentTarget).then(onUpdateInhibitorScaffoldUrl);
    },
    [onUpdateInhibitorScaffoldUrl]
  );

  let updateScaffoldScale = useCallback(
    (newVal: number) => {
      controls.scaffoldScale = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateSubstrateStrength = useCallback(
    (newVal: number) => {
      controls.substrateStrength = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateSubstrateMode = useCallback(
    (newVal: "band" | "infinite") => {
      controls.substrateMode = newVal;
      console.log("mode", newVal);
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateRandomComponent = useCallback(
    (newVal: number) => {
      controls.randomComponent = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateNeighbourhoodRadius = useCallback(
    (newVal: number) => {
      controls.neighbourhoodRadius = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateBranchingDensity = useCallback(
    (newVal: number) => {
      controls.branchingDensity = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateGrowingDensity = useCallback(
    (newVal: number) => {
      controls.growingDensity = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateMaxGrowingAge = useCallback(
    (newVal: number) => {
      controls.maxGrowingAge = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateMaxBranchingAge = useCallback(
    (newVal: number) => {
      controls.maxBranchingAge = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let toggleFieldHypothesis = useCallback(() => {
    controls.fieldHypothesisOn = !controls.fieldHypothesisOn;
    onControlsChange(controls);
  }, [controls, onControlsChange]);
  let toggleAutotropism = useCallback(() => {
    controls.autotropismOn = !controls.autotropismOn;
    onControlsChange(controls);
  }, [controls, onControlsChange]);
  let updateAutotropismImpact = useCallback(
    (newVal: number) => {
      controls.autotropismImpact = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateAutotropismInertia = useCallback(
    (newVal: number) => {
      controls.tropismInertia = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let toggleSensorTurn1 = useCallback(() => {
    controls.sensorTurn1On = !controls.sensorTurn1On;
    onControlsChange(controls);
  }, [controls, onControlsChange]);
  let toggleGravitropism = useCallback(() => {
    controls.gravitropismOn = !controls.gravitropismOn;
    onControlsChange(controls);
  }, [controls, onControlsChange]);
  let updateGravitropismAngle = useCallback(
    (newVal: number) => {
      controls.gravitropismAngle = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateGravitropismImpact = useCallback(
    (newVal: number) => {
      controls.gravitropismImpact = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let toggleParallelTropism = useCallback(() => {
    controls.parallelTropismOn = !controls.parallelTropismOn;
    onControlsChange(controls);
  }, [controls, onControlsChange]);
  let togglePositiveTropism = useCallback(() => {
    controls.positiveTropismOn = !controls.positiveTropismOn;
    onControlsChange(controls);
  }, [controls, onControlsChange]);
  let toggleHorizontalPlaneTropism = useCallback(() => {
    controls.horizontalPlaneTropismOn = !controls.horizontalPlaneTropismOn;
    onControlsChange(controls);
  }, [controls, onControlsChange]);
  let updateIsosurfaceRadius = useCallback(
    (newVal: number) => {
      controls.isosurfaceRadius = [newVal, newVal];
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateIsosurfaceNeighbourhood = useCallback(
    (newVal: number) => {
      controls.isosurfaceNeighbourhood = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateIsosurfaceSubdivision = useCallback(
    (newVal: number) => {
      controls.isosurfaceSubdivision = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateSurfaceGrowthAge = useCallback(
    (newVal: number) => {
      controls.surfaceGrowthAge = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateBackgroundColor = useCallback(
    (color: [number, number, number, number], alpha: number) => {
      controls.backgroundColor = [color[0], color[1], color[2], alpha];
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateColors = useCallback(
    (palette: any) => {
      controls.colors = palette.map((s: any) => [
        take(hexRgb(s.color, { format: "array" }), 3),
        +s.pos
      ]);
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateDisplacementNoiseScale = useCallback(
    (component: 0 | 1 | 2, newVal: number) => {
      controls.displacementNoiseScale[component] = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateDisplacementAmplitude = useCallback(
    (newVal: number) => {
      controls.displacementAmplitude = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateDisplacementBloom = useCallback(
    (newVal: number) => {
      controls.displacementBloom = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateDisplacementDelta = useCallback(
    (newVal: number) => {
      controls.displacementDelta = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateDisplacementDirection = useCallback(
    (component: 0 | 1 | 2, newVal: number) => {
      controls.displacementDirection[component] = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateDisplacementMesoScale = useCallback(
    (component: 0 | 1 | 2, newVal: number) => {
      controls.displacementMesoScale[component] = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateBumpNoiseScale = useCallback(
    (component: 0 | 1 | 2, newVal: number) => {
      controls.bumpNoiseScale[component] = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );
  let updateBumpNoiseAmplitude = useCallback(
    (newVal: number) => {
      controls.bumpNoiseAmplitude = newVal;
      onControlsChange(controls);
    },
    [controls, onControlsChange]
  );

  return (
    <div className="controls">
      <div className="playControls">
        <div className="playControls--age">
          <div className="playControls--ageTitle">Step</div>
          <div className="playControls--ageNumber">{age}</div>
        </div>
        <div className="playControls--buttons">
          <button onClick={onTogglePlay} className="buttonPlayPause">
            {isPlaying ? "Pause" : "Start growth"}
          </button>
          <button onClick={onStep} className="buttonStepper">
            Grow one step
          </button>
          <button onClick={onUpdateMesh} className="buttonStepper">
            Force mesh update
          </button>
          <button onClick={onDownloadMesh} className="buttonDownload">
            Download mesh
          </button>
          {/*
            <button onClick={downloadMeshSequence} className="buttonDownload">
              Download mesh sequence
            </button>
          */}
        </div>
        <div className="playControls--visibilities">
          <div className="inputCheckbox">
            <input
              id="showScaffold"
              type="checkbox"
              defaultChecked={isShowingScaffold}
              onClick={onToggleScaffold}
            />
            <label htmlFor="showScaffold">Show scaffold</label>
          </div>
          <div className="inputCheckbox">
            <input
              id="showMycelium"
              type="checkbox"
              defaultChecked={isShowingMycelium}
              onClick={onToggleMycelium}
            />
            <label htmlFor="showMycelium">Show mycelium</label>
          </div>
          <div className="inputCheckbox">
            <input
              id="showIsosurface"
              type="checkbox"
              defaultChecked={isShowingIsosurface}
              onClick={onToggleIsosurface}
            />
            <label htmlFor="showIsosurface">Show isosurface</label>
          </div>
        </div>
      </div>
      <div className="playControls--inputs">
        <div className="inputFile">
          <label>
            Upload Substrate Scaffold (.obj)
            <input type="file" onChange={updateSubstrateScaffoldModel} />
          </label>
        </div>
        <div className="inputFile">
          <label>
            Upload Inhibitor Scaffold (.obj)
            <input type="file" onChange={updateInhibitorScaffoldModel} />
          </label>
        </div>
        <div className="inputRange">
          <label>Scaffold scale: {controls.scaffoldScale}</label>
          <input
            type="range"
            min="0.1"
            max="200"
            step="0.1"
            value={controls.scaffoldScale}
            onChange={evt => updateScaffoldScale(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>
            Scaffold substrate strength: {controls.substrateStrength}
          </label>
          <input
            type="range"
            min="0"
            max="0.5"
            step="0.01"
            value={controls.substrateStrength}
            onChange={evt => updateSubstrateStrength(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRadio">
          <input
            id="bandSubstrate"
            type="radio"
            name="substrateMode"
            value={"band"}
            defaultChecked={controls.substrateMode === "band"}
            onChange={evt => updateSubstrateMode("band")}
          />
          <label htmlFor="bandSubstrate">Band scaffold</label>
          <input
            id="infiniteSubstrate"
            type="radio"
            name="substrateMode"
            value={"infinite"}
            defaultChecked={controls.substrateMode === "infinite"}
            onChange={evt => updateSubstrateMode("infinite")}
          />
          <label htmlFor="infiniteSubstrate">Solid scaffold</label>
        </div>
        <div className="inputRange">
          <label>Branching probability: {controls.randomComponent}</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.05"
            value={controls.randomComponent}
            onChange={evt => updateRandomComponent(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>Neighbourhood radius: {controls.neighbourhoodRadius}</label>
          <input
            type="range"
            min="0"
            max="40"
            step="1"
            value={controls.neighbourhoodRadius}
            onChange={evt =>
              updateNeighbourhoodRadius(+evt.currentTarget.value)
            }
          />
        </div>
        <div className="inputRange">
          <label>Branching density: {controls.branchingDensity}</label>
          <input
            type="range"
            min="0"
            max="10"
            step="0.5"
            value={controls.branchingDensity}
            onChange={evt => updateBranchingDensity(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>Growing density: {controls.growingDensity}</label>
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            value={controls.growingDensity}
            onChange={evt => updateGrowingDensity(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>Max branching age: {controls.maxBranchingAge}</label>
          <input
            type="range"
            min="0"
            max="300"
            step="1"
            value={controls.maxBranchingAge}
            onChange={evt => updateMaxBranchingAge(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>Max growth age: {controls.maxGrowingAge}</label>
          <input
            type="range"
            min="0"
            max="300"
            step="1"
            value={controls.maxGrowingAge}
            onChange={evt => updateMaxGrowingAge(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputCheckbox">
          <input
            id="fieldHypothesis"
            type="checkbox"
            defaultChecked={controls.fieldHypothesisOn}
            onClick={toggleFieldHypothesis}
          />
          <label htmlFor="fieldHypothesis">Field hypothesis</label>
        </div>
        <div className="inputCheckbox">
          <input
            id="autotropism"
            type="checkbox"
            defaultChecked={controls.autotropismOn}
            onClick={toggleAutotropism}
          />
          <label htmlFor="autotropism">Autotropism</label>
        </div>
        <div className="inputRange">
          <label>Autotropism impact: {controls.autotropismImpact}</label>
          <input
            type="range"
            min="-1"
            max="0"
            step="0.1"
            value={controls.autotropismImpact}
            onChange={evt => updateAutotropismImpact(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>Tropism inertia: {controls.tropismInertia}</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={controls.tropismInertia}
            onChange={evt => updateAutotropismInertia(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputCheckbox">
          <input
            id="higherOrientation1"
            type="checkbox"
            defaultChecked={controls.sensorTurn1On}
            onClick={toggleSensorTurn1}
          />
          <label htmlFor="higherOrientation1">Higher orientation 1</label>
        </div>
        <div className="inputCheckbox">
          <input
            id="gravitropism"
            type="checkbox"
            defaultChecked={controls.gravitropismOn}
            onClick={toggleGravitropism}
          />
          <label htmlFor="gravitropism">Gravitropism</label>
        </div>
        <div className="inputRange">
          <label>Gravitropism angle: {controls.gravitropismAngle}</label>
          <input
            type="range"
            min="0"
            max="90"
            step="1"
            value={controls.gravitropismAngle}
            onChange={evt => updateGravitropismAngle(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>Gravitropism impact: {controls.gravitropismImpact}</label>
          <input
            type="range"
            min="0"
            max="0.01"
            step="0.001"
            value={controls.gravitropismImpact}
            onChange={evt => updateGravitropismImpact(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputCheckbox">
          <input
            id="parallelTropism"
            type="checkbox"
            defaultChecked={controls.parallelTropismOn}
            onClick={toggleParallelTropism}
          />
          <label htmlFor="parallelTropism">Parallel tropism</label>
        </div>
        <div className="inputCheckbox">
          <input
            id="positiveTropism"
            type="checkbox"
            defaultChecked={controls.positiveTropismOn}
            onClick={togglePositiveTropism}
          />
          <label htmlFor="positiveTropism">Positive tropism</label>
        </div>
        <div className="inputCheckbox">
          <input
            id="horizontalPlaneTropism"
            type="checkbox"
            defaultChecked={controls.horizontalPlaneTropismOn}
            onClick={toggleHorizontalPlaneTropism}
          />
          <label htmlFor="horizontalPlaneTropism">
            Horizontal plane tropism
          </label>
        </div>
        <div className="inputRange">
          <label>Isosurface radius: {controls.isosurfaceRadius}</label>
          <input
            type="range"
            min="1"
            max="50"
            step="1"
            value={controls.isosurfaceRadius[0]}
            onChange={evt => updateIsosurfaceRadius(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>
            Isosurface min point count: {controls.isosurfaceNeighbourhood}
          </label>
          <input
            type="range"
            min="1"
            max="20"
            step="1"
            value={controls.isosurfaceNeighbourhood}
            onChange={evt =>
              updateIsosurfaceNeighbourhood(+evt.currentTarget.value)
            }
          />
        </div>
        <div className="inputRange">
          <label>
            Isosurface mesh subdivision (careful!):{" "}
            {controls.isosurfaceSubdivision}
          </label>
          <input
            type="range"
            min="1"
            max="5"
            step="1"
            value={controls.isosurfaceSubdivision}
            onChange={evt =>
              updateIsosurfaceSubdivision(+evt.currentTarget.value)
            }
          />
        </div>
        <div className="inputRange">
          <label>Surface growth age: {controls.surfaceGrowthAge}</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={controls.surfaceGrowthAge}
            onChange={evt => updateSurfaceGrowthAge(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputColor">
          <label>Background colour</label>
          <div className="inputColor--picker">
            <ColorPicker
              color={`rgb(${take(controls.backgroundColor, 3).join(",")})`}
              alpha={controls.backgroundColor[3] * 100}
              onChange={(e: any) =>
                updateBackgroundColor(
                  hexRgb(e.color, { format: "array" }),
                  e.alpha / 100
                )
              }
            />
            <span className="inputColor--value">
              rgba({controls.backgroundColor.join(", ")})
            </span>
          </div>
        </div>
        <div className="inputColor">
          <label>Mushroom colour</label>
          <div className="inputColor--picker">
            <GradientBuilder
              {...{
                width: 220,
                height: 32,
                palette: controls.colors.map(([color, stop]) => ({
                  pos: stop,
                  color: rgbHex(...color)
                })),
                onPaletteChange: (p: any) => updateColors(p)
              }}
            >
              <GradientWrappedColorPicker />
            </GradientBuilder>
          </div>
        </div>
        <div className="inputVectorRange">
          <label>
            Displacement noise scale:{" "}
            {controls.displacementNoiseScale.join(", ")}
          </label>
          {[0, 1, 2].map(cmp => (
            <input
              key={cmp}
              type="range"
              min="1"
              max="100"
              step="1"
              value={controls.displacementNoiseScale[cmp]}
              onChange={evt =>
                updateDisplacementNoiseScale(
                  cmp as 0 | 1 | 2,
                  +evt.currentTarget.value
                )
              }
            />
          ))}
        </div>
        <div className="inputRange">
          <label>
            Displacement amplitude: {controls.displacementAmplitude}
          </label>
          <input
            type="range"
            min="0"
            max="30"
            step="0.1"
            value={controls.displacementAmplitude}
            onChange={evt =>
              updateDisplacementAmplitude(+evt.currentTarget.value)
            }
          />
        </div>
        <div className="inputRange">
          <label>Displacement bloom: {controls.displacementBloom}</label>
          <input
            type="range"
            min="0"
            max="15"
            step="1"
            value={controls.displacementBloom}
            onChange={evt => updateDisplacementBloom(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputRange">
          <label>Displacement delta: {controls.displacementDelta}</label>
          <input
            type="range"
            min="-2"
            max="2"
            step="0.1"
            value={controls.displacementDelta}
            onChange={evt => updateDisplacementDelta(+evt.currentTarget.value)}
          />
        </div>
        <div className="inputVectorRange">
          <label>
            Displacement direction: {controls.displacementDirection.join(", ")}
          </label>
          {[0, 1, 2].map(cmp => (
            <input
              key={cmp}
              type="range"
              min="-10"
              max="10"
              step="0.1"
              value={controls.displacementDirection[cmp]}
              onChange={evt =>
                updateDisplacementDirection(
                  cmp as 0 | 1 | 2,
                  +evt.currentTarget.value
                )
              }
            />
          ))}
        </div>
        <div className="inputVectorRange">
          <label>
            Displacement mesoscale: {controls.displacementMesoScale.join(", ")}
          </label>
          {[0, 1, 2].map(cmp => (
            <input
              key={cmp}
              type="range"
              min="1"
              max="500"
              step="1"
              value={controls.displacementMesoScale[cmp]}
              onChange={evt =>
                updateDisplacementMesoScale(
                  cmp as 0 | 1 | 2,
                  +evt.currentTarget.value
                )
              }
            />
          ))}
        </div>
        <div className="inputVectorRange">
          <label>Bump noise scale: {controls.bumpNoiseScale.join(", ")}</label>
          {[0, 1, 2].map(cmp => (
            <input
              key={cmp}
              type="range"
              min="0.05"
              max="1.0"
              step="0.05"
              value={controls.bumpNoiseScale[cmp]}
              onChange={evt =>
                updateBumpNoiseScale(cmp as 0 | 1 | 2, +evt.currentTarget.value)
              }
            />
          ))}
        </div>
        <div className="inputRange">
          <label>Bump amplitude: {controls.bumpNoiseAmplitude}</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={controls.bumpNoiseAmplitude}
            onChange={evt => updateBumpNoiseAmplitude(+evt.currentTarget.value)}
          />
        </div>
      </div>
    </div>
  );
};

function loadFile(el: HTMLInputElement): Promise<string> {
  return new Promise((res, rej) => {
    let files = el.files;
    if (files && files.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.addEventListener(
        "load",
        () => res(reader.result as string),
        false
      );
    } else {
      rej("No files");
    }
  });
}

interface GradientWrappedColorPickerProps {
  onSelect?: (c: string) => void;
}
const GradientWrappedColorPicker: React.FC<GradientWrappedColorPickerProps> = ({
  onSelect,
  ...rest
}) => {
  console.log(rest);
  return (
    (rest as any).color && (
      <ColorPickerPanel
        {...rest}
        enableAlpha={false}
        onChange={(c: any) => onSelect!(c.color)}
      />
    )
  );
};
