import { Geometry, Vector3 } from "three";

const THREE = require("three");

interface loadScaffoldGeometryProps {
    url: string,
    subtract: 'self' | Vector3,
    initScale?: number,
    fitToScale?: number
}
export function loadScaffoldGeometry({
    url,
    subtract = new Vector3(0, 0, 0),
    initScale,
    fitToScale
}: loadScaffoldGeometryProps): Promise<[Geometry, Vector3, number]> {
    return new Promise(res => {
        new (THREE as any).OBJLoader().load(url, (model: any) => {
            let geom = new Geometry();
            geom.fromBufferGeometry(model.children[0].geometry);
            geom.computeBoundingBox();
            geom.computeVertexNormals();
            let center = new Vector3();
            geom.boundingBox!.getCenter(center);

            let scaleBy = 1;
            if (fitToScale) {
                let sz = new Vector3();
                geom.boundingBox!.getSize(sz);
                let bBoxScale = Math.max(sz.x, Math.max(sz.y, sz.z));
                scaleBy = fitToScale / bBoxScale;
            } else if (initScale) {
                scaleBy = initScale;
            }
            for (let vert of geom.vertices) {
                vert.sub(subtract === "self" ? center : subtract);
                vert.multiplyScalar(scaleBy);
            }
            geom.computeBoundingBox();
            res([geom, center, scaleBy]);
        });
    });
}

export function collectScaffoldVertices(scaffold: Geometry, scale: number): [Float32Array, Uint32Array, Float32Array] {
    let scaffoldVertices = new Float32Array(scaffold.vertices.length * 3);
    let scaffoldFaces = new Uint32Array(scaffold.faces.length * 3);
    let scaffoldVertexNormals = new Float32Array(scaffold.vertices.length * 3);
    for (let i = 0; i < scaffold.vertices.length; i++) {
        scaffoldVertices[i * 3] = scaffold.vertices[i].x * scale;
        scaffoldVertices[i * 3 + 1] = scaffold.vertices[i].y * scale;
        scaffoldVertices[i * 3 + 2] = scaffold.vertices[i].z * scale;
    }
    for (let i = 0; i < scaffold.faces.length; i++) {
        scaffoldFaces[i * 3] = scaffold.faces[i].a;
        scaffoldFaces[i * 3 + 1] = scaffold.faces[i].b;
        scaffoldFaces[i * 3 + 2] = scaffold.faces[i].c;

        scaffoldVertexNormals[scaffold.faces[i].a * 3] = scaffold.faces[i].vertexNormals[0].x;
        scaffoldVertexNormals[scaffold.faces[i].a * 3 + 1] = scaffold.faces[i].vertexNormals[0].y;
        scaffoldVertexNormals[scaffold.faces[i].a * 3 + 2] = scaffold.faces[i].vertexNormals[0].z;
        scaffoldVertexNormals[scaffold.faces[i].b * 3] = scaffold.faces[i].vertexNormals[1].x;
        scaffoldVertexNormals[scaffold.faces[i].b * 3 + 1] = scaffold.faces[i].vertexNormals[1].y;
        scaffoldVertexNormals[scaffold.faces[i].b * 3 + 2] = scaffold.faces[i].vertexNormals[1].z;
        scaffoldVertexNormals[scaffold.faces[i].c * 3] = scaffold.faces[i].vertexNormals[2].x;
        scaffoldVertexNormals[scaffold.faces[i].c * 3 + 1] = scaffold.faces[i].vertexNormals[2].y;
        scaffoldVertexNormals[scaffold.faces[i].c * 3 + 2] = scaffold.faces[i].vertexNormals[2].z;
    }
    return [scaffoldVertices, scaffoldFaces, scaffoldVertexNormals];
}
