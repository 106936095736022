import React, { useState, useCallback } from "react";
import classNames from "classnames";
import { CompactPicker, RGBColor, ColorResult } from "react-color";
import { cloneDeep, isString } from "lodash";

import { ColorScheme, Color } from "./core";

import "./ColorSettings.scss";

interface ColorSettingsProps {
  backgroundColor: string;
  mushroomColors: ColorScheme;
  onUpdateBackgroundColor: (newBgColor: string) => void;
  onUpdateMushroomColors: (newColors: ColorScheme) => void;
}
export const ColorSettings: React.FC<ColorSettingsProps> = React.memo(
  ({
    mushroomColors,
    backgroundColor,
    onUpdateMushroomColors,
    onUpdateBackgroundColor,
  }) => {
    let [selectedColorIdx, setSelectedColorIdx] = useState<"bg" | number>("bg");
    let selectedColor =
      selectedColorIdx === "bg"
        ? backgroundColor
        : mushroomColors[selectedColorIdx][0];

    let onChangeSelectedColor = useCallback(
      (newColor: ColorResult) => {
        if (selectedColorIdx === "bg") {
          onUpdateBackgroundColor(newColor.hex);
        } else {
          let newColors = cloneDeep(mushroomColors);
          newColors[selectedColorIdx][0][0] = newColor.rgb.r;
          newColors[selectedColorIdx][0][1] = newColor.rgb.g;
          newColors[selectedColorIdx][0][2] = newColor.rgb.b;
          onUpdateMushroomColors(newColors);
        }
      },
      [mushroomColors, onUpdateMushroomColors, selectedColorIdx]
    );

    return (
      <div className="colorSettings">
        <div className="colorPicker">
          <CompactPicker
            color={
              isString(selectedColor)
                ? selectedColor
                : ({
                    r: selectedColor[0],
                    g: selectedColor[1],
                    b: selectedColor[2],
                  } as RGBColor)
            }
            onChange={(c) => onChangeSelectedColor(c)}
            styles={{
              default: {
                compact: { width: "100%", backgroundColor: "black" },
                clear: { backgroundColor: "black" },
              },
            }}
          />
        </div>
        <div className="colorSettingsColors">
          <div
            className={classNames("colorSettingsColor", {
              isSelected: selectedColorIdx === "bg",
            })}
            style={{ backgroundColor: backgroundColor }}
            onClick={() => setSelectedColorIdx("bg")}
          ></div>
          {mushroomColors.map(([color, stop], index) => (
            <div
              key={index}
              className={classNames("colorSettingsColor", {
                isSelected: selectedColorIdx === index,
              })}
              style={{ backgroundColor: `rgb(${color.join(",")})` }}
              onClick={() => setSelectedColorIdx(index)}
            ></div>
          ))}
        </div>
      </div>
    );
  }
);
