import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Authoring from "./Authoring";
import Exhibition from "./Exhibition";
import Keyboard from "./Keyboard";

import "./App.scss";
import { WebApp } from "./WebApp";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/screen/:screen?">
          <Exhibition />
        </Route>
        <Route path="/keyboard/:screen?">
          <Keyboard />
        </Route>
        <Route path="/authoring">
          <Authoring />
        </Route>
        <Route path="/">
          <WebApp />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
