import { Vector3, Raycaster, Mesh, MeshBasicMaterial, DoubleSide, Box3, Geometry, BufferAttribute, DynamicDrawUsage } from 'three';
import * as THREE from 'three';
import { random, maxBy, range, uniqueId, clone, times, shuffle, initial, last, isNumber } from 'lodash';
import EventEmitter from 'events';
import * as csg from '@jscad/csg';

import { ControlOptions } from "./core";
import { collectScaffoldVertices } from "./scaffolds";
import { installOBJLoader } from './three/OBJLoader';
import { getLeftSpacing, getRightSpacing, WORDSPACE, SCAFFOLDS, LEADING, MAX_LINE_WIDTH, SPORES, GROWTH_POINT_THRESHOLDS } from './alphabet';
import { MAX_VERTICES, MAX_INDICES } from './MyceliumIsosurface';
import { Lock } from './lock';

const Worker = require("./worker").default;

installOBJLoader(THREE);

const MAX_TOTAL_AGE = 5000;
const ISOSURFACE_EXPANSION_AGE = 300;

const SURFACE_GROWTH_DELAY = 300;
const SURFACE_GROWTH_STEPS = 300;
export const SCAFFOLD_SCALE = 10.5;

const VERTEX_BUF_LENGTH = MAX_VERTICES * 3;
const FACE_BUF_LENGTH = MAX_INDICES * 3;
const VERTEX_NORMAL_BUF_LENGTH = MAX_VERTICES * 3;

const mobileSafari = (navigator.userAgent.match(/(iPhone)/) ||
    navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */);

export interface IsosurfaceData {
    stillGrowing: boolean;
    subdivided: boolean;
    surfaceAge: number;
    updateRanges: Int32Array;
    vertices: Float32Array;
    vertexUpdateRange: number;
    vertexAttribute: BufferAttribute,
    faces: Uint32Array;
    faceUpdateRange: number;
    faceAttribute: BufferAttribute,
    vertexNormals: Float32Array;
    vertexNormalAttribute: BufferAttribute,
    vertexNormalUpdateRange: number;
    sharedBuffer?: SharedArrayBuffer;
    sharedLock?: any
}

export function initIsosurfaceData() {
    let sharedBuffers = 'SharedArrayBuffer' in window && 'Atomics' in window;
    if (sharedBuffers) {
        let sharedBuffer = new SharedArrayBuffer(
            Lock.NUMBYTES +
            3 * Uint32Array.BYTES_PER_ELEMENT +
            VERTEX_BUF_LENGTH * Float32Array.BYTES_PER_ELEMENT +
            FACE_BUF_LENGTH * Uint32Array.BYTES_PER_ELEMENT +
            VERTEX_NORMAL_BUF_LENGTH * Float32Array.BYTES_PER_ELEMENT
        );
        Lock.initialize(sharedBuffer, 0)
        let vertices = new Float32Array(sharedBuffer, Lock.NUMBYTES + 3 * Uint32Array.BYTES_PER_ELEMENT, VERTEX_BUF_LENGTH);
        let vertexAttribute = new BufferAttribute(vertices, 3)
        vertexAttribute.setUsage(DynamicDrawUsage);
        let faces = new Uint32Array(sharedBuffer, Lock.NUMBYTES + 3 * Uint32Array.BYTES_PER_ELEMENT + VERTEX_BUF_LENGTH * Float32Array.BYTES_PER_ELEMENT, FACE_BUF_LENGTH);
        let faceAttribute = new BufferAttribute(faces, 3)
        faceAttribute.setUsage(DynamicDrawUsage);
        let vertexNormals = new Float32Array(sharedBuffer, Lock.NUMBYTES + 3 * Uint32Array.BYTES_PER_ELEMENT + VERTEX_BUF_LENGTH * Float32Array.BYTES_PER_ELEMENT + FACE_BUF_LENGTH * Uint32Array.BYTES_PER_ELEMENT, VERTEX_NORMAL_BUF_LENGTH);
        let vertexNormalAttribute = new BufferAttribute(vertexNormals, 3)
        vertexNormalAttribute.setUsage(DynamicDrawUsage);
        return {
            stillGrowing: true,
            subdivided: false,
            surfaceAge: 0,
            updateRanges: new Uint32Array(sharedBuffer, Lock.NUMBYTES, 3),
            vertices,
            vertexAttribute,
            faces,
            faceAttribute,
            vertexNormals,
            vertexNormalAttribute,
            sharedBuffer,
            sharedLock: new Lock(sharedBuffer, 0)
        }
    } else {
        let vertices = new Float32Array(VERTEX_BUF_LENGTH);
        let vertexAttribute = new BufferAttribute(vertices, 3)
        vertexAttribute.setUsage(DynamicDrawUsage);
        let faces = new Uint32Array(FACE_BUF_LENGTH);
        let faceAttribute = new BufferAttribute(faces, 3)
        faceAttribute.setUsage(DynamicDrawUsage);
        let vertexNormals = new Float32Array(VERTEX_NORMAL_BUF_LENGTH);
        let vertexNormalAttribute = new BufferAttribute(vertexNormals, 3)
        vertexNormalAttribute.setUsage(DynamicDrawUsage);
        return {
            stillGrowing: true,
            subdivided: false,
            surfaceAge: 0,
            updateRanges: new Uint32Array(3),
            vertices,
            vertexAttribute,
            faces,
            faceAttribute,
            vertexNormals,
            vertexNormalAttribute,
            sharedBuffers
        }
    }
};

export class ExhibitionGlyphScaffold {
    public loaded = false;
    public width = 0;
    public height = 0;
    public spores: Vector3[] = [];

    constructor(public chr: string, public substrateScaffold: Geometry, public inhibitorScaffold?: Geometry) {
        this.width = (substrateScaffold.boundingBox!.max.x - substrateScaffold.boundingBox!.min.x) * SCAFFOLD_SCALE;
        this.height = (substrateScaffold.boundingBox!.max.y - substrateScaffold.boundingBox!.min.y) * SCAFFOLD_SCALE;
    }

}

interface ExhibitionGlyphProps {
    chr: string,
    annihilation: number | 'n/a',
    substrateScaffold: Geometry,
    inhibitorScaffold?: Geometry,
    displacementNoiseScale: number[],
    includeBaseSections?: boolean,
    substrateStrength?: number,
    displacementAmplitude?: number,
    displacementScale?: number,
    glSupportsDerivatives: boolean
}
export class ExhibitionGlyph extends EventEmitter {
    public id = uniqueId();
    public annihilation: number | 'n/a' = 'n/a';
    public scaffold: ExhibitionGlyphScaffold;
    private worker: Worker;
    public paused = false;

    public myceliumSections = new Float32Array(0);
    public isosurfaceData = initIsosurfaceData();
    public controls?: ControlOptions;

    private baseDisplacementMesoScale = [random(120, 300), random(120, 300), random(120, 300)];
    private stillGrowing = true;
    private stoppedGrowingAtAge = -1;

    constructor({ chr, annihilation, substrateScaffold, inhibitorScaffold, displacementNoiseScale = calculateDisplacementNoiseScale(), includeBaseSections = false, substrateStrength, displacementAmplitude, displacementScale, glSupportsDerivatives }: ExhibitionGlyphProps) {
        super();
        this.annihilation = annihilation;
        this.scaffold = new ExhibitionGlyphScaffold(chr, substrateScaffold, inhibitorScaffold);
        this.worker = new Worker();

        if (this.isosurfaceData.sharedBuffer) {
            console.log('Initialising with shared buffers');
            this.worker.postMessage({
                type: "init",
                sharedBuffer: this.isosurfaceData.sharedBuffer,
                vertexBufferLength: VERTEX_BUF_LENGTH,
                faceBufferLength: FACE_BUF_LENGTH,
                vertexNormalBufferLenth: VERTEX_NORMAL_BUF_LENGTH,
                skipSubdivision: mobileSafari,
                glSupportsDerivatives
            });
        } else {
            console.log('Initialising without shared buffers');
            this.worker.postMessage({
                type: "init",
                sharedBuffers: false,
                skipSubdivision: mobileSafari,
                glSupportsDerivatives
            });
        }

        this.worker.postMessage({
            type: "toggleBaseSections",
            on: includeBaseSections
        });
        this.worker.postMessage({
            type: "toggleIsosurface",
            on: true
        });
        this.worker.addEventListener('message', async (event: MessageEvent) => {
            switch (event.data.type) {
                case "initControls":
                    this.controls = {
                        ...event.data.initialControls,
                        substrateMode: "infinite",
                        substrateStrength: 0.01,
                        randomComponent: 0.1,
                        neighbourhoodRadius: 16,
                        branchingDensity: 1.5,
                        growingDensity: 20,
                        maxBranchingAge: 120,
                        maxGrowingAge: 98,
                        autotropismOn: true,
                        autotropismImpact: -1,
                        tropismInertia: 0.1,
                        gravitropismOn: true,
                        gravitropismAngle: random(0, 360),
                        gravitropismImpact: this.scaffold.chr === 'custom' ? 0 : 0.002,
                        calculateIsosurfaceEvery: 10,
                        isosurfaceRadius: [5, 25],
                        isosurfaceNeighbourhood: 1,
                        isosurfaceSubdivision: 1,
                        colors: [[[206, 204, 184], 0.0], [[209, 133, 96], 0.4], [[240, 120, 76], 0.45], [[234, 134, 156], 0.5], [[166, 63, 207], 1.0]],
                        displacementDirection: [random(-5, 5, true), random(-5, 5, true), random(-5, 5, true)],
                        displacementMesoScale: this.baseDisplacementMesoScale,
                        displacementNoiseScale,
                        displacementBloom: 0,
                        displacementDelta: 0.1,
                        bumpNoiseScale: [0.75, 0.1, 0.1],
                        bumpNoiseAmplitude: 0.2,
                        maxTotalGrowingAge: MAX_TOTAL_AGE,
                        isosurfaceExpansionAge: ISOSURFACE_EXPANSION_AGE,
                    } as ControlOptions;
                    if (this.annihilation !== 'n/a') {
                        this.updateAnnihilation(this.annihilation);
                    }
                    if (isNumber(substrateStrength)) {
                        this.updateSubstrateStrength(substrateStrength);
                    }
                    if (isNumber(displacementAmplitude)) {
                        this.updateDisplacementAmplitude(displacementAmplitude);
                    }
                    if (isNumber(displacementScale)) {
                        this.updateDisplacementScale(displacementScale);
                    }
                    this.worker.postMessage({ type: "setControls", controls: this.controls });
                    this.spore();
                    break;
                case "updateSections":
                    let updateIsosurface = false;
                    let updateTheRest = false;
                    if (!event.data.stillGrowing && this.stillGrowing) {
                        this.stillGrowing = false;
                        this.stoppedGrowingAtAge = event.data.age;
                        updateTheRest = true;
                    }
                    if (event.data.hasIsosurface) {
                        if (!this.isosurfaceData.sharedBuffers) {
                            let updateRanges = new Uint32Array(event.data.isosurfaceUpdateRanges);
                            let vertices = new Float32Array(event.data.isosurfaceVertices);
                            let faces = new Uint32Array(event.data.isosurfaceFaces);
                            let vertexNormals = new Float32Array(
                                event.data.isosurfaceVertexNormals || []
                            );
                            this.isosurfaceData.updateRanges.set(updateRanges, 0);
                            this.isosurfaceData.vertices.set(vertices, 0);
                            this.isosurfaceData.faces.set(faces, 0);
                            this.isosurfaceData.vertexNormals.set(vertexNormals, 0);
                        }

                        this.isosurfaceData = {
                            ...this.isosurfaceData,
                            stillGrowing: event.data.stillGrowing,
                            subdivided: event.data.subdivided,
                            surfaceAge: this.calculateSurfaceAge(event.data.age)
                        };

                        updateIsosurface = true;
                    } else {
                        this.isosurfaceData = {
                            ...this.isosurfaceData,
                            stillGrowing: event.data.stillGrowing,
                            surfaceAge: this.calculateSurfaceAge(event.data.age)
                        };
                    }
                    if (event.data.sections) {
                        this.myceliumSections = new Float32Array(event.data.sections);
                        updateTheRest = true;
                    }

                    if (updateIsosurface) {
                        if (this.isosurfaceData.sharedLock) {
                            await this.isosurfaceData.sharedLock.asyncLock();
                            this.applyIsosurfaceToBufferAttributes();
                            try {
                                this.emit('isosurfaceUpdate');
                            } finally {
                                this.isosurfaceData.sharedLock.unlock();
                            }
                        } else {
                            this.applyIsosurfaceToBufferAttributes();
                            this.emit('isosurfaceUpdate');
                        }
                    }
                    if (updateTheRest) {
                        this.emit('generalUpdate');
                    }
                    break;
            }
        });
    }

    private applyIsosurfaceToBufferAttributes() {
        this.isosurfaceData.vertexAttribute.updateRange.offset = 0;
        this.isosurfaceData.vertexAttribute.updateRange.count = this.isosurfaceData.updateRanges[0];
        this.isosurfaceData.vertexAttribute.needsUpdate = this.isosurfaceData.updateRanges[0] > 0;
        this.isosurfaceData.faceAttribute.updateRange.offset = 0;
        this.isosurfaceData.faceAttribute.updateRange.count = this.isosurfaceData.updateRanges[1];
        this.isosurfaceData.faceAttribute.needsUpdate = this.isosurfaceData.updateRanges[1] > 0;
        this.isosurfaceData.vertexNormalAttribute.updateRange.offset = 0;
        this.isosurfaceData.vertexNormalAttribute.updateRange.count = this.isosurfaceData.updateRanges[2];
        this.isosurfaceData.vertexNormalAttribute.needsUpdate = this.isosurfaceData.updateRanges[2] > 0;
    }

    public updateAnnihilation(newAnnihilation: number) {
        this.annihilation = newAnnihilation;
        if (this.controls) {
            this.controls = {
                ...this.controls,
                displacementAmplitude: 5 + this.annihilation * 15,
                displacementDelta: 0.1 + this.annihilation * 0.2,
                displacementMesoScale: [
                    this.baseDisplacementMesoScale[0] + (this.annihilation - 0.5) * 50,
                    this.baseDisplacementMesoScale[1] + (this.annihilation - 0.5) * 50,
                    this.baseDisplacementMesoScale[2] + (this.annihilation - 0.5) * 50,
                ],
                //isosurfaceRadius: [10 + (1 - this.annihilation - 0.5) * 4, 20 + (1 - this.annihilation - 0.5) * 4],
                isosurfaceNeighbourhood: 1 + this.annihilation * 0.75,
                substrateStrength: 0.000001 + 0.009999 * (1 - this.annihilation)
            };
            this.worker.postMessage({ type: "setControls", controls: this.controls });
        }
    }

    public updateSubstrateStrength(newSubstrateStrength: number) {
        if (this.controls) {
            this.controls = {
                ...this.controls,
                escapeProbability: newSubstrateStrength
            };
            this.worker.postMessage({ type: "setControls", controls: this.controls });
        }
    }


    public updateDisplacementAmplitude(newDisplacementAmplitude: number) {
        if (this.controls) {
            this.controls = {
                ...this.controls,
                displacementAmplitude: newDisplacementAmplitude
            };
        }
    }

    public updateDisplacementScale(newDisplacementScale: number) {
        if (this.controls) {
            this.controls = {
                ...this.controls,
                displacementDelta: 0.1 + newDisplacementScale * 0.2,
                displacementMesoScale: [
                    this.baseDisplacementMesoScale[0] + (newDisplacementScale - 0.5) * 70,
                    this.baseDisplacementMesoScale[1] + (newDisplacementScale - 0.5) * 70,
                    this.baseDisplacementMesoScale[2] + (newDisplacementScale - 0.5) * 70,
                ],
            };
        }
    }

    public dispose() {
        this.worker.terminate();
    }

    private calculateSurfaceAge(mushroomAge: number) {
        if (this.controls && !this.stillGrowing) {
            let surfaceAge =
                (mushroomAge - this.stoppedGrowingAtAge - SURFACE_GROWTH_DELAY) / SURFACE_GROWTH_STEPS;
            return easeInOutQuart(Math.max(0, Math.min(1, surfaceAge)));
        }
        return 0;
    }

    private async spore() {
        let [substrateScaffoldVertices, substrateScaffoldFaces, substrateScaffoldVertexNormals] = collectScaffoldVertices(this.scaffold.substrateScaffold, SCAFFOLD_SCALE);
        if (this.scaffold.inhibitorScaffold) {
            let [inhibitorScaffoldVertices, inhibitorScaffoldFaces, inhibitorScaffoldVertexNormals] = collectScaffoldVertices(this.scaffold.inhibitorScaffold, SCAFFOLD_SCALE);
            this.worker.postMessage(
                {
                    type: "setScaffold",
                    scaffoldVertices: substrateScaffoldVertices.buffer,
                    scaffoldFaces: substrateScaffoldFaces.buffer,
                    scaffoldVertexNormals: substrateScaffoldVertexNormals.buffer,
                    inhibitorScaffoldVertices: inhibitorScaffoldVertices.buffer,
                    inhibitorScaffoldFaces: inhibitorScaffoldFaces.buffer,
                    inhibitorScaffoldVertexNormals: inhibitorScaffoldVertexNormals.buffer,
                    growthPointThreshold: GROWTH_POINT_THRESHOLDS[this.scaffold.chr] || 0.9
                },
                [
                    substrateScaffoldVertices.buffer,
                    substrateScaffoldFaces.buffer,
                    substrateScaffoldVertexNormals.buffer,
                    inhibitorScaffoldVertices.buffer,
                    inhibitorScaffoldFaces.buffer,
                    inhibitorScaffoldVertexNormals.buffer
                ]
            );
        } else {
            this.worker.postMessage(
                {
                    type: "setScaffold",
                    scaffoldVertices: substrateScaffoldVertices.buffer,
                    scaffoldFaces: substrateScaffoldFaces.buffer,
                    scaffoldVertexNormals: substrateScaffoldVertexNormals,
                    growthPointThreshold: GROWTH_POINT_THRESHOLDS[this.scaffold.chr] || 0.9
                },
                [
                    substrateScaffoldVertices.buffer,
                    substrateScaffoldFaces.buffer,
                    substrateScaffoldVertexNormals.buffer
                ]
            );
        }

        let spores: Vector3[];
        if (SPORES.hasOwnProperty(this.scaffold.chr)) {
            spores = shuffle(SPORES[this.scaffold.chr].map(s => new Vector3().fromArray(s)));
        } else {
            let substrateTestMesh = new Mesh(
                this.scaffold.substrateScaffold.clone(),
                new MeshBasicMaterial({ side: DoubleSide })
            );
            substrateTestMesh.scale.set(SCAFFOLD_SCALE, SCAFFOLD_SCALE, SCAFFOLD_SCALE);
            substrateTestMesh.updateMatrix();
            substrateTestMesh.updateMatrixWorld();

            let inhibitorTestMesh: Mesh | undefined = undefined;
            if (this.scaffold.inhibitorScaffold) {
                let inhibitorTestMesh = new Mesh(
                    this.scaffold.inhibitorScaffold.clone(),
                    new MeshBasicMaterial({ side: DoubleSide })
                );
                inhibitorTestMesh.scale.set(SCAFFOLD_SCALE, SCAFFOLD_SCALE, SCAFFOLD_SCALE);
                inhibitorTestMesh.updateMatrix();
                inhibitorTestMesh.updateMatrixWorld(true);
            }

            spores = generateSpores(substrateTestMesh, inhibitorTestMesh);
        }

        let sendSpore = () => {
            if (this.isosurfaceData && !this.isosurfaceData.stillGrowing) {
                return;
            }
            let spore = spores.shift();
            this.worker.postMessage({
                type: "addSpore",
                sporeOrigin: spore!.toArray()
            });
            if (!this.paused) {
                this.worker.postMessage({ type: "start" });
            }
            if (spores.length) {
                setTimeout(sendSpore, random(1000, 2000));
            }
        };
        setTimeout(sendSpore, 1000);
    }

    pause() {
        this.paused = true;
        this.worker && this.worker.postMessage({ type: 'stop' });
    }
    play() {
        this.paused = false;
        this.worker && this.worker.postMessage({ type: 'start' });
    }
}

function generateSpores(substrateMesh: Mesh, inhibitorMesh?: Mesh) {
    substrateMesh.geometry.computeBoundingBox();
    let bBox = substrateMesh.geometry.boundingBox!.setFromCenterAndSize(
        substrateMesh.geometry.boundingBox!.getCenter(new Vector3()),
        substrateMesh.geometry.boundingBox!.getSize(new Vector3()).multiplyScalar(SCAFFOLD_SCALE)
    );
    let candidates = range(20).map(() => generateSporesCandidate(bBox, substrateMesh, inhibitorMesh));
    return maxBy(candidates, spores => {
        let sz = new Vector3();
        let sporeSpheres = spores.map(spore => csg.CSG.sphere({
            center: spore.toArray(),
            radius: bBox.getSize(sz).length() / 5,
            resolution: 4
        }));
        let sphereUnion = sporeSpheres.reduce((u, s) => u.union(s));
        let box = csg.CSG.cube({ center: bBox.getCenter(sz).toArray(), radius: bBox.getSize(sz).toArray() })
        let boxSpheresIntersect = box.intersect(sphereUnion);
        let vol = boxSpheresIntersect.getFeatures('volume');
        return vol;
    }) as Vector3[];
}

function generateSporesCandidate(bBox: Box3, substrateMesh: Mesh, inhibitorMesh?: Mesh) {
    let sanity = 500;
    let spores: Vector3[] = [];
    let raycaster = new Raycaster();
    while (spores.length < 12 && sanity-- > 0) {
        let candidate = new Vector3(
            random(bBox.min.x, bBox.max.x),
            random(bBox.min.y, bBox.max.y),
            random(bBox.min.z, bBox.max.z)
        );
        raycaster.set(candidate, new Vector3(1, 1, 1));
        let substrateIntersections = raycaster.intersectObject(
            substrateMesh
        );
        let inhibitorIntersections = inhibitorMesh ? raycaster.intersectObject(
            inhibitorMesh
        ) : [];
        if (
            substrateIntersections.length % 2 === 1 &&
            inhibitorIntersections.length % 2 === 0
        ) {
            spores.push(candidate);
        }
    }
    return spores;
}

export function calculateDisplacementNoiseScale(displacementNoiseTotal = random(50, 160)) {
    // let relDisplacementNoiseX = random(0.1, 0.5);
    // let relDisplacementNoiseZ = Math.max(0.1, random(0, 1 - relDisplacementNoiseX));
    // let relDisplacementNoiseY = Math.max(0.1, random(0, 1 - relDisplacementNoiseX - relDisplacementNoiseZ));
    let relDisplacementNoiseX = 0.3;
    let relDisplacementNoiseY = 0.4;
    let relDisplacementNoiseZ = 0.3;

    let displacementNoiseX = displacementNoiseTotal * relDisplacementNoiseX;
    let displacementNoiseY = displacementNoiseTotal * relDisplacementNoiseY;
    let displacementNoiseZ = displacementNoiseTotal * relDisplacementNoiseZ;

    return [displacementNoiseX, displacementNoiseY, displacementNoiseZ];
}

export function typesetGlyphs(glyphs: (ExhibitionGlyph | 'space')[]) {
    let nScaffold = new ExhibitionGlyphScaffold('N', ...SCAFFOLDS.N);
    let nSize = [nScaffold.width, nScaffold.height] as [number, number];

    let linesWrappedByWidth = divideText(glyphs, nSize, nSize[0] * MAX_LINE_WIDTH);
    let linesWrappedBySpace = glyphs.reduce((lines, glyph) => glyph === 'space' ? [...lines, []] : [...initial(lines), [...last(lines)!, glyph]], [[]] as (ExhibitionGlyph | 'space')[][]);
    let lines = (linesWrappedByWidth.length > linesWrappedBySpace.length) ? linesWrappedByWidth : linesWrappedBySpace;

    let totalHeight = lines.length * nSize[1] + (lines.length - 1) * nSize[1] * LEADING;
    let firstLineY = -(totalHeight / 2) + nSize[1] / 2;
    let result: { [glyphId: string]: Vector3 } = {};
    for (let i = 0; i < lines.length; i++) {
        let y = firstLineY + nSize[1] * i + nSize[1] * LEADING * i;
        let lineGlyphs = lines[i];
        let xs = getGlyphLineXs(lineGlyphs, nSize);

        for (let j = 0; j < lineGlyphs.length; j++) {
            if (lineGlyphs[j] !== 'space') {
                let glyph = (lineGlyphs[j] as ExhibitionGlyph);
                result[glyph.id] = new Vector3(xs[j] + glyph.scaffold.width / 2, 0, y);
            }
        }
    }
    return result;
}

function getGlyphLineXs(glyphs: (ExhibitionGlyph | 'space')[], nSize: [number, number]) {
    let totalWidth = 0;
    for (let i = 0; i < glyphs.length; i++) {
        if (glyphs[i] === 'space') {
            if (i > 0 && i < glyphs.length - 1) {
                totalWidth += WORDSPACE * nSize[0];
            }
        } else {
            let glyph = glyphs[i] as ExhibitionGlyph;
            totalWidth += glyph.scaffold.width;
            if (i > 0) {
                totalWidth += getLeftSpacing(glyph.scaffold.chr) * nSize[0];
            }
            if (i < glyphs.length - 1) {
                totalWidth += getRightSpacing(glyph.scaffold.chr) * nSize[0];
            }
        }
    }
    let left = -totalWidth / 2;
    let xs = glyphs.reduce(
        (xs, g, i) => [
            ...xs,
            xs[xs.length - 1] +
            width(g, nSize[0]) +
            (i < glyphs.length - 1 ? leftSpacing(glyphs[i + 1], nSize[0]) : 0) +
            (i < glyphs.length - 1 ? rightSpacing(g, nSize[0]) : 0)
        ],
        [left]
    );
    return xs;
}

function width(g: ExhibitionGlyph | 'space', nWidth: number) {
    if (g === 'space') {
        return WORDSPACE * nWidth;
    } else {
        return g.scaffold.width;
    }
}

function leftSpacing(g: ExhibitionGlyph | 'space', nWidth: number) {
    if (g === 'space') {
        return 0;
    } else {
        return getLeftSpacing(g.scaffold.chr) * nWidth;
    }
}

function rightSpacing(g: ExhibitionGlyph | 'space', nWidth: number) {
    if (g === 'space') {
        return 0;
    } else {
        return getRightSpacing(g.scaffold.chr) * nWidth
    }
}

function divideText(glyphs: (ExhibitionGlyph | 'space')[], nSize: [number, number], maxWidth: number) {
    let remainingGlyphs = clone(glyphs);
    let count = remainingGlyphs.length;

    let xs = getGlyphLineXs(glyphs, nSize);
    let offsets: number[] = xs.map(x => x - xs[0]);
    let minima = [0].concat(times(count, () => 10 ** 20));
    let breaks = times(count + 1, () => 0);

    function cost(i: number, j: number) {
        let w = offsets[j] - offsets[i] + j - i - 1;
        if (w > maxWidth) {
            return 10 ** 10;
        }
        return minima[i] + (maxWidth - w) ** 2;
    }

    function search(i0: number, j0: number, i1: number, j1: number) {
        let stack = [[i0, j0, i1, j1]];
        while (stack.length) {
            let [i0, j0, i1, j1] = stack.pop() as number[];
            if (j0 < j1) {
                let j = Math.floor((j0 + j1) / 2);
                for (let i of range(i0, i1)) {
                    let c = cost(i, j);
                    if (c <= minima[j]) {
                        minima[j] = c;
                        breaks[j] = i;
                    }
                }
                stack.push([breaks[j], j + 1, i1, j1]);
                stack.push([i0, j0, breaks[j] + 1, j]);
            }
        }
    }

    let n = count + 1;
    let i = 0;
    let offset = 0;
    while (true) {
        let r = Math.min(n, 2 ** (i + 1));
        let edge = 2 ** i + offset;
        search(0 + offset, edge, edge, r + offset);
        let x = minima[r - 1 + offset];
        let breaked = false;
        for (let j of range(2 ** i, r - 1)) {
            let y = cost(j + offset, r - 1 + offset);
            if (y <= x) {
                n -= j;
                i = 0;
                offset += j;
                breaked = true;
                break;
            }
        }
        if (!breaked) {
            if (r === n) {
                break;
            }
            i = i + 1;
        }
    }

    let lines = [];
    let j = count;
    while (j > 0) {
        i = breaks[j];
        let glyphs = remainingGlyphs.slice(i, j);
        lines.push(glyphs);
        j = i;
    }
    lines.reverse();
    return lines;
}


let easeInOutQuart = (t: number) => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;