import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from "react";

import * as THREE from "three";
import {
  Vector3,
  Mesh,
  BufferGeometry,
  BufferAttribute,
  ClampToEdgeWrapping,
  LinearFilter,
  UnsignedByteType,
  UVMapping,
  RGBAFormat,
} from "three";
import { useThree, useFrame } from "react-three-fiber";
import { useSpring, animated } from "react-spring/three";

import { ControlOptions, Color } from "./core";

import displacementMapParsVertex from "./shader/displacement_map_pars_vertex.glsl";
import displacementMapVertex from "./shader/displacement_map_vertex.glsl";
import bumpMapParsFragment from "./shader/bump_map_pars_fragment.glsl";
import normalFragmentBegin from "./shader/normal_fragment_begin.glsl";
import normalFragmentMaps from "./shader/normal_fragment_maps.glsl";
import colorFragment from "./shader/color_fragment.glsl";
import { getEnvMap } from "./envmapLoader";
import { ExhibitionGlyph } from "./ExhibitionGlyph";
import { generateNoiseTexture } from "./shader/noiseTexture";

let PLACEHOLDER_TEXTURE = new THREE.DataTexture(
  new Uint8Array(4),
  1,
  1,
  RGBAFormat,
  UnsignedByteType,
  UVMapping,
  ClampToEdgeWrapping,
  ClampToEdgeWrapping,
  LinearFilter,
  LinearFilter
);
PLACEHOLDER_TEXTURE.generateMipmaps = false;
export const MAX_VERTICES = 300000;
export const MAX_INDICES = 900000;

interface MyceliumIsosurfaceProps {
  translate: Vector3;
  stillGrowing: boolean;
  useComputedNormals: boolean;
  controls: ControlOptions;
  vertexAttribute: BufferAttribute;
  faceAttribute: BufferAttribute;
  vertexNormalAttribute: BufferAttribute;
  glyph?: ExhibitionGlyph;
  colors?: [Color, number][];
}
export const MyceliumIsosurface: React.FC<MyceliumIsosurfaceProps> = React.memo(
  ({
    translate,
    stillGrowing,
    useComputedNormals,
    controls,
    vertexAttribute,
    faceAttribute,
    vertexNormalAttribute,
    glyph,
    colors,
  }) => {
    let meshRef = useRef<Mesh>();
    let noiseTexture = useMemo(() => generateNoiseTexture(256, 256), []);

    let [envMap, setEnvMap] = useState<THREE.Texture>();

    let { gl, scene } = useThree();

    useEffect(() => {
      getEnvMap(gl).then(setEnvMap);
    }, [gl]);

    const [meshSpring, setMeshSpring] = useSpring(() => ({
      position: translate.toArray(),
      config: { mass: 1, tension: 280, friction: 120 },
    }));
    useEffect(() => {
      setMeshSpring({ position: translate.toArray() });
    }, [translate]);

    let uniforms = useMemo(
      () => ({
        color1: { value: new THREE.Color(0xebdfca) },
        color2: { value: new THREE.Color(0xebdfca) },
        color3: { value: new THREE.Color(0xdfd0b6) },
        color4: { value: new THREE.Color(0xd1bd9f) },
        color5: { value: new THREE.Color(0xc7b08e) },
        colorStop1: { value: 0.0 },
        colorStop2: { value: 0.25 },
        colorStop3: { value: 0.5 },
        colorStop4: { value: 0.75 },
        colorStop5: { value: 1.0 },
        surfaceGrowthAge: { value: 0 },
        displacementNoiseScale: { value: new Vector3(0, 0, 0) },
        displacementAmplitude: { value: 0 },
        displacementBloom: { value: 0 },
        displacementDelta: { value: 0 },
        displacementDirection: { value: new Vector3(0, 0, 0) },
        displacementMesoscale: { value: new Vector3(0, 0, 0) },
        bumpNoiseScale: { value: new Vector3(0, 0, 0) },
        noiseTexture: { type: "t", value: noiseTexture },
      }),
      [noiseTexture]
    );
    let computedNormalsUniform = useMemo(() => ({ value: true }), []);

    useEffect(() => {
      let cs = colors || controls.colors;
      uniforms.color1.value.fromArray(cs[0][0].map((c) => c / 255));
      uniforms.color2.value.fromArray(cs[1][0].map((c) => c / 255));
      uniforms.color3.value.fromArray(cs[2][0].map((c) => c / 255));
      uniforms.color4.value.fromArray(cs[3][0].map((c) => c / 255));
      uniforms.color5.value.fromArray(cs[4][0].map((c) => c / 255));
      uniforms.colorStop1.value = cs[0][1];
      uniforms.colorStop2.value = cs[1][1];
      uniforms.colorStop3.value = cs[2][1];
      uniforms.colorStop4.value = cs[3][1];
      uniforms.colorStop5.value = cs[4][1];
      uniforms.displacementNoiseScale.value.fromArray(
        controls.displacementNoiseScale
      );
      uniforms.displacementAmplitude.value = controls.displacementAmplitude;
      uniforms.displacementBloom.value = controls.displacementBloom;
      uniforms.displacementDelta.value = controls.displacementDelta;
      uniforms.displacementDirection.value.fromArray(
        controls.displacementDirection
      );
      uniforms.displacementMesoscale.value.fromArray(
        controls.displacementMesoScale
      );
      uniforms.bumpNoiseScale.value.fromArray(controls.bumpNoiseScale);
    }, [colors, controls, uniforms, stillGrowing]);

    useEffect(() => {
      if (!meshRef.current) return;
      if (!useComputedNormals) {
        computedNormalsUniform.value = false;
      }
    }, [useComputedNormals]);

    let setGeometries = useCallback(
      (mesh: Mesh) => {
        let geom = mesh.geometry as BufferGeometry;
        if (geom.getAttribute("position") !== vertexAttribute) {
          geom.setAttribute("position", vertexAttribute);
        }
        if (geom.getAttribute("normal") !== vertexNormalAttribute) {
          geom.setAttribute("normal", vertexNormalAttribute);
        }
        if (geom.getIndex() !== faceAttribute) {
          geom.setIndex(faceAttribute);
        }
      },
      [vertexAttribute, faceAttribute, vertexNormalAttribute]
    );

    useFrame(() => {
      if (uniforms.surfaceGrowthAge && glyph) {
        uniforms.surfaceGrowthAge.value = glyph.isosurfaceData.surfaceAge;
      }
      if (meshRef.current && glyph) {
        let geom = meshRef.current!.geometry as BufferGeometry;
        if (
          geom.drawRange.start !== 0 ||
          geom.drawRange.count !== glyph.isosurfaceData.updateRanges[1]
        ) {
          geom.setDrawRange(0, glyph.isosurfaceData.updateRanges[1]);
        }
      }
    });

    let onShaderCompile = useCallback(
      (shader: THREE.Shader, computedNormalsUniform: { value: boolean }) => {
        shader.uniforms.surfaceGrowthAge = uniforms.surfaceGrowthAge;
        shader.uniforms.displacementNoiseScale =
          uniforms.displacementNoiseScale;
        shader.uniforms.displacementAmplitude = uniforms.displacementAmplitude;
        shader.uniforms.displacementBloom = uniforms.displacementBloom;
        shader.uniforms.displacementDelta = uniforms.displacementDelta;
        shader.uniforms.displacementDirection = uniforms.displacementDirection;
        shader.uniforms.displacementMesoscale = uniforms.displacementMesoscale;
        shader.uniforms.bumpNoiseScale = uniforms.bumpNoiseScale;
        shader.uniforms.color1 = uniforms.color1;
        shader.uniforms.color2 = uniforms.color2;
        shader.uniforms.color3 = uniforms.color3;
        shader.uniforms.color4 = uniforms.color4;
        shader.uniforms.color5 = uniforms.color5;
        shader.uniforms.colorStop1 = uniforms.colorStop1;
        shader.uniforms.colorStop2 = uniforms.colorStop2;
        shader.uniforms.colorStop3 = uniforms.colorStop3;
        shader.uniforms.colorStop4 = uniforms.colorStop4;
        shader.uniforms.colorStop5 = uniforms.colorStop5;
        shader.uniforms.hasComputedNormals = computedNormalsUniform;
        shader.uniforms.noiseTexture = uniforms.noiseTexture;

        shader.vertexShader = shader.vertexShader.replace(
          "#include <displacementmap_pars_vertex>",
          displacementMapParsVertex
        );
        shader.vertexShader = shader.vertexShader.replace(
          "#include <displacementmap_vertex>",
          displacementMapVertex
        );
        shader.vertexShader = shader.vertexShader.replace(
          "#include <uv_pars_vertex>",
          "#include <uv_pars_vertex>\nvarying vec3 vWorldPosition;"
        );
        shader.vertexShader = shader.vertexShader.replace(
          "#include <worldpos_vertex>",
          "#include <worldpos_vertex>\nvWorldPosition = worldPosition.xyz;"
        );
        shader.fragmentShader = shader.fragmentShader.replace(
          "#include <bumpmap_pars_fragment>",
          bumpMapParsFragment
        );
        shader.fragmentShader = shader.fragmentShader.replace(
          "#include <normal_fragment_begin>",
          normalFragmentBegin
        );
        shader.fragmentShader = shader.fragmentShader.replace(
          "#include <normal_fragment_maps>",
          normalFragmentMaps
        );
        shader.fragmentShader = shader.fragmentShader.replace(
          "#include <envmap_physical_pars_fragment>",
          "#include <envmap_physical_pars_fragment>\nvarying vec3 vWorldPosition;"
        );
        shader.fragmentShader = shader.fragmentShader.replace(
          "#include <logdepthbuf_fragment>",
          "#include <logdepthbuf_fragment>\n" + colorFragment
        );
        // console.log("vertex", shader.vertexShader);
        // console.log("fragment", shader.fragmentShader);
      },
      [uniforms]
    );

    return envMap ? (
      <animated.mesh
        ref={meshRef}
        {...meshSpring}
        frustumCulled={false}
        onUpdate={(m: Mesh) => setGeometries(m)}
      >
        <bufferGeometry attach="geometry" />
        <meshStandardMaterial
          attach="material"
          metalness={0}
          roughness={0.7}
          envMap={envMap}
          bumpMap={PLACEHOLDER_TEXTURE}
          bumpScale={controls.bumpNoiseAmplitude}
          displacementMap={PLACEHOLDER_TEXTURE}
          transparent={true}
          onUpdate={(m) =>
            (m.onBeforeCompile = (shader) =>
              onShaderCompile(shader, computedNormalsUniform))
          }
        />
      </animated.mesh>
    ) : (
      <></>
    );
  }
);
