import { Geometry } from 'three';

import { loadScaffoldGeometry } from "./scaffolds";

import aSubstrateUrl from "./assets/A_BASE.obj";
import aInhibitorUrl from "./assets/A_CO.obj";
import bSubstrateUrl from "./assets/B_BASE.obj";
import bInhibitorUrl from "./assets/B_CO.obj";
import cSubstrateUrl from "./assets/C_BASE.obj";
import cInhibitorUrl from "./assets/C_CO.obj";
import dSubstrateUrl from "./assets/D_BASE.obj";
import dInhibitorUrl from "./assets/D_CO.obj";
import eSubstrateUrl from "./assets/E_BASE.obj";
import eInhibitorUrl from "./assets/E_CO.obj";
import fSubstrateUrl from "./assets/F_BASE.obj";
import fInhibitorUrl from "./assets/F_CO.obj";
import gSubstrateUrl from "./assets/G_BASE.obj";
import gInhibitorUrl from "./assets/G_CO.obj";
import hSubstrateUrl from "./assets/H_BASE.obj";
import hInhibitorUrl from "./assets/H_CO.obj";
import iSubstrateUrl from "./assets/I_BASE.obj";
import iInhibitorUrl from "./assets/I_CO.obj";
import jSubstrateUrl from "./assets/J_BASE.obj";
import jInhibitorUrl from "./assets/J_CO.obj";
import kSubstrateUrl from "./assets/K_BASE.obj";
import kInhibitorUrl from "./assets/K_CO.obj";
import lSubstrateUrl from "./assets/L_BASE.obj";
import lInhibitorUrl from "./assets/L_CO.obj";
import mSubstrateUrl from "./assets/M_BASE.obj";
import mInhibitorUrl from "./assets/M_CO.obj";
import nSubstrateUrl from "./assets/N_BASE.obj";
import nInhibitorUrl from "./assets/N_CO.obj";
import oSubstrateUrl from "./assets/O_BASE.obj";
import oInhibitorUrl from "./assets/O_CO.obj";
import pSubstrateUrl from "./assets/P_BASE.obj";
import pInhibitorUrl from "./assets/P_CO.obj";
import qSubstrateUrl from "./assets/Q_BASE.obj";
import qInhibitorUrl from "./assets/Q_CO.obj";
import rSubstrateUrl from "./assets/R_BASE.obj";
import rInhibitorUrl from "./assets/R_CO.obj";
import sSubstrateUrl from "./assets/S_BASE.obj";
import sInhibitorUrl from "./assets/S_CO.obj";
import tSubstrateUrl from "./assets/T_BASE.obj";
import tInhibitorUrl from "./assets/T_CO.obj";
import uSubstrateUrl from "./assets/U_BASE.obj";
import uInhibitorUrl from "./assets/U_CO.obj";
import vSubstrateUrl from "./assets/V_BASE.obj";
import vInhibitorUrl from "./assets/V_CO.obj";
import wSubstrateUrl from "./assets/W_BASE.obj";
import wInhibitorUrl from "./assets/W_CO.obj";
import xSubstrateUrl from "./assets/X_BASE.obj";
import xInhibitorUrl from "./assets/X_CO.obj";
import ySubstrateUrl from "./assets/Y_BASE.obj";
import yInhibitorUrl from "./assets/Y_CO.obj";
import zSubstrateUrl from "./assets/Z_BASE.obj";
import zInhibitorUrl from "./assets/Z_CO.obj";

export const SCAFFOLD_URLS = {
    A: [aSubstrateUrl, aInhibitorUrl],
    B: [bSubstrateUrl, bInhibitorUrl],
    C: [cSubstrateUrl, cInhibitorUrl],
    D: [dSubstrateUrl, dInhibitorUrl],
    E: [eSubstrateUrl, eInhibitorUrl],
    F: [fSubstrateUrl, fInhibitorUrl],
    G: [gSubstrateUrl, gInhibitorUrl],
    H: [hSubstrateUrl, hInhibitorUrl],
    I: [iSubstrateUrl, iInhibitorUrl],
    J: [jSubstrateUrl, jInhibitorUrl],
    K: [kSubstrateUrl, kInhibitorUrl],
    L: [lSubstrateUrl, lInhibitorUrl],
    M: [mSubstrateUrl, mInhibitorUrl],
    N: [nSubstrateUrl, nInhibitorUrl],
    O: [oSubstrateUrl, oInhibitorUrl],
    P: [pSubstrateUrl, pInhibitorUrl],
    Q: [qSubstrateUrl, qInhibitorUrl],
    R: [rSubstrateUrl, rInhibitorUrl],
    S: [sSubstrateUrl, sInhibitorUrl],
    T: [tSubstrateUrl, tInhibitorUrl],
    U: [uSubstrateUrl, uInhibitorUrl],
    V: [vSubstrateUrl, vInhibitorUrl],
    W: [wSubstrateUrl, wInhibitorUrl],
    X: [xSubstrateUrl, xInhibitorUrl],
    Y: [ySubstrateUrl, yInhibitorUrl],
    Z: [zSubstrateUrl, zInhibitorUrl]
} as { [chr: string]: [string, string] };

export const SCAFFOLDS = {} as { [chr: string]: [Geometry, Geometry] };

let loadPromises: Promise<any>[] = [];
export function loadScaffolds() {
    if (loadPromises.length === 0) {
        for (let chr of Object.keys(SCAFFOLD_URLS)) {
            let [substrateUrl, inhibitorUrl] = SCAFFOLD_URLS[chr];
            loadPromises.push(loadScaffoldGeometry({ url: substrateUrl, subtract: 'self' }).then(([substrateGeom, substrateCenter]) => {
                return loadScaffoldGeometry({ url: inhibitorUrl, subtract: substrateCenter }).then(([inhibitorGeom]) => {
                    SCAFFOLDS[chr] = [substrateGeom, inhibitorGeom];
                });
            }));
        }
    }
    return Promise.all(loadPromises);
}

const UNIT_NS = 1.2 / 9;
export const WORDSPACE = 5 * UNIT_NS;
export const LEADING = 2 * UNIT_NS;
const A = 1.5 * UNIT_NS;
const B = 1 * UNIT_NS;
const C = 0.5 * UNIT_NS;
const D = 0 * UNIT_NS;

export const MAX_LINE_WIDTH = 12; // *nWidth

export function getLeftSpacing(chr: string) {
    switch (chr) {
        case 'A': return C;
        case 'B': return A;
        case 'C': return B;
        case 'D': return A;
        case 'E': return A;
        case 'F': return A;
        case 'G': return B;
        case 'H': return A;
        case 'I': return B;
        case 'J': return C;
        case 'K': return B;
        case 'L': return B;
        case 'M': return A;
        case 'N': return A;
        case 'O': return B;
        case 'P': return A;
        case 'Q': return B;
        case 'R': return A;
        case 'S': return B;
        case 'T': return C;
        case 'U': return B;
        case 'V': return D;
        case 'W': return D;
        case 'X': return C;
        case 'Y': return D;
        case 'Z': return B;
        default: return 0;
    }
}

export function getRightSpacing(chr: string) {
    switch (chr) {
        case 'A': return C;
        case 'B': return B;
        case 'C': return B;
        case 'D': return B;
        case 'E': return A;
        case 'F': return B;
        case 'G': return C;
        case 'H': return A;
        case 'I': return B;
        case 'J': return B;
        case 'K': return B;
        case 'L': return C;
        case 'M': return A;
        case 'N': return A;
        case 'O': return B;
        case 'P': return B;
        case 'Q': return B;
        case 'R': return B;
        case 'S': return B;
        case 'T': return C;
        case 'U': return B;
        case 'V': return D;
        case 'W': return D;
        case 'X': return C;
        case 'Y': return D;
        case 'Z': return B;
        default: return 0;
    }
}

export const GROWTH_POINT_THRESHOLDS = {
    A: 0.9,
    B: 0.9,
    C: 0.70,
    D: 0.83,
    E: 0.91,
    F: 0.92,
    G: 0.8,
    H: 0.9,
    I: 0.9,
    J: 0.95,
    K: 0.9,
    L: 0.9,
    M: 0.92,
    N: 0.9,
    O: 0.8,
    P: 0.92,
    Q: 0.85,
    R: 0.9,
    S: 0.9,
    T: 0.81,
    U: 0.85,
    V: 0.8,
    W: 0.85,
    X: 0.8,
    Y: 0.9,
    Z: 0.87
} as { [chr: string]: number };

export const SPORES = {
    A: [
        [-65.4648418610971, 44.935501459497374, 34], [-72.3979828388803, -45.919973344097045, 33], [-44.24573021219837, 82.35383147528418, 39], [58.17645483910502, -38.12944592257415, 16], [40.770456028534625, -3.4102393088231224, -91], [21.81108531225445, 64.17560514168295, 36], [88.86265124589053, -64.9237281986899, 95],
        [38.969102737980336, 75.683992371622, 24], [82.00953697051483, 43.83607202610449, 83], [-34.11550729050066, 36.47992852373309, -83], [38.51813593369505, -71.49137441999258, 17], [-94.14503349125008, 53.63707026356411, 92], [33.476970151000216, -4.321693569526531, -95], [-30.968318016312082, 54.19905929881847, 26],
        [22.692545119109496, 6.772807146974159, -95], [-48.48085662999704, 70.87864527149367, 29], [69.21478278805327, 19.681322130761203, 86], [-29.234363327171877, -13.579596909028965, 26], [55.75135482776113, -39.50011907332561, 32], [73.27105638566988, 40.55591296851938, 18], [-54.12704263389238, -21.697997309428033, -36],
        [-70.91988896997239, -11.238194162950165, 6], [69.81243059311748, -18.00185033348525, 59], [25.26710120048685, 34.38935420761152, 36], [80.95691985267644, 44.84877180121748, 95], [16.15489061386623, -53.97576892723133, 32], [63.05938091705045, -73.67287339934308, 97], [50.58768310702928, -11.745336023881904, -48],
        [15.859086194206213, 45.014849019504226, -99], [76.98184088437159, 1.1594478064464653, 83], [-61.58459019665248, -83.78895231641329, 85], [-37.056651194315094, -1.2345521446704737, -81], [61.66061056674715, 68.12874182871985, 42], [-68.21735881825298, 15.003152905935806, 29], [-83.29879046406789, 49.84851083746554, 75],
        [-75.35748328107505, 75.32628844691297, 97], [-14.449418620578768, 67.4313514539947, 33], [-64.3223707846731, -23.120272468791242, 7], [86.83182372105694, 8.381913615480372, 45], [59.700176253079405, -20.57055425685921, -36], [80.34426668316505, -10.476984221510946, 95], [-91.37766669734003, 20.8185469698918, 81],
        [92.19201530162871, -5.748254610506805, 81], [-49.50983135719956, -0.26148553351441706, -19], [78.87946232991081, -24.27935365662229, 33], [-91.19489585175076, -26.376666361975126, 99], [-42.03192399501938, -79.93242267540015, 40], [-65.53231660721329, 57.89645211510506, 36], [49.97479756898636, 68.6894142620757, 41],
        [51.310175943391215, 2.040767988273032, -25], [48.349217495822785, 37.71360280896579, -17], [-66.23281790609947, 43.25209619920088, 5], [92.44396601783242, 41.68603975355475, 95], [59.35808790631128, 85.47905836082862, 84], [19.12042964093581, 52.30876958332229, -84], [38.866756087632325, -55.07216493735452, 38],
        [62.89788654021041, 18.88526907246603, 20], [-20.28590396000226, 17.114096912645152, 31], [-64.76172762187316, -30.10607968382122, 12], [68.34514939307985, -55.39427879530351, 78], [-15.807590300330475, 70.0463788046822, 30], [-56.43479858631583, 4.808048218796003, -50], [46.99627493019281, -52.20797930294394, -31],
        [57.3835315914541, 31.408540823789508, 5], [-1.1114057950925513, 14.035795678061177, -94], [-44.34197826679805, 22.78533085291403, 38], [53.27377005732549, -23.4784531775545, -48], [-63.864250002022736, -66.22411447730401, 36], [15.80990534857574, 76.49802045097593, 29], [70.07302409024754, 16.472114995770795, 82]
    ],
    B: [
        [69.96259152250093, -18.05551476226819, 25.84740474247404], [22.029143927427015, -1.4847856222376379, -95.86000952923979], [58.6135794889893, 71.14840377061526, -50.56223116424658], [19.606682979519945, -48.09346395089694, 89.58013316714425], [-65.63382237291512, 58.01105632220623, 11.432698882410435], [-50.473182548825186, -83.25565262578696, 68.7464260746531], [51.01728143061939, 17.119590032304274, 31.317525233896646],
        [-78.66117591906243, 42.89089608270794, -41.15738980082782], [70.49246108759351, -22.600968009335418, -47.72031153537447], [-58.14336513741253, 52.2245823418497, 27.917740381839437], [-71.50791014827853, -35.44145311067311, 29.978372937235378], [26.004434749538518, 24.348535829448096, 76.99945929226334], [59.65387408192487, 13.570354408176016, -15.524194616006554], [-32.29455314327927, -60.50891086233819, 78.43113636121154],
        [9.063849712392553, 45.999301604022406, 80.4316670359471], [-58.69886734945173, 90.31596255850775, 59.812656947146394], [-72.63646911421978, -36.80610742635837, -2.399431880587443], [45.520360693488584, -20.157111162019135, 90.29859754065967], [-35.28862544374996, 17.938976572171967, 4.1488673196915755], [68.3460393795896, 14.694190380647768, -25.426219175359776], [33.20572595801279, -80.62601851512588, -24.21837658713835],
        [46.995625729591666, -40.311028081866226, -74.69273214757553], [-34.408658005876426, 28.88693110416918, -88.22390114143131], [35.257687105527964, 13.073268119710733, -62.017145664590466], [-50.4552767231454, 50.65239926624972, -33.52762585765696], [-61.242805667021194, -61.67032166668023, 71.49544505582614], [-78.22696557750791, 68.36476731469426, 57.63393315427777], [-17.05330822477515, -45.440296658722254, -5.151018525362872],
        [28.853149555224007, 34.32226320666203, -86.07803998380983], [62.991952087594825, 48.85430646242875, 29.033889116906693], [-76.83746336252955, 78.04437652565537, 35.128716206375486], [0.19266960550294243, -29.34669944781777, 91.40377052936259], [-51.696853919802884, 70.52790503779462, -66.88947232520664], [38.07514957361596, -67.89666805167272, 71.0463138902291], [44.3669942769756, -67.92700093226645, -62.97380744408243],
        [42.6653875825925, -49.697720583891794, -25.995188216029305], [-34.07535115677385, 18.311609082479464, 99.01205876685333], [-19.930271602008666, -31.9977916778589, 92.1510636352352], [54.35173660251371, -32.9213286558486, 17.827676668197782], [-66.00768050877737, 37.2046418394458, 50.38747913594119], [58.582568883289326, 14.531616553450618, -52.80468345300315], [54.675773635477356, 41.96697450512022, 29.48552454275054]
    ],
    C: [
        [63.37070925853553, 26.888954608088937, -72], [-74.75442733147798, 65.77075071076447, -11], [-11.89046327994403, -64.04373764185365, 81], [-2.823246420439361, -13.425448468827042, 55], [59.76808642423859, -62.97287548831447, 49], [9.32900547349378, 69.26163479334357, 76], [-51.764156396309936, 44.565739944100386, -31],
        [9.166739338920763, 66.51962107630271, 65], [-79.46798672170246, 46.25590606502456, 0], [-34.484850416791076, 9.485478872823535, 44], [-3.058937227344174, -63.51093659434307, 70], [-56.704290817198384, -19.506864562945708, 6], [3.417345576143788, 70.88593574552647, -74], [21.44000347847981, -35.51878106437404, 88],
        [26.396722449858856, -58.126406331880204, 81], [11.396626031517158, 18.094692304225518, 95], [-23.863700858442726, 69.5196057864894, 73], [81.68039849002349, 34.29753522255291, 51], [-63.125586773419876, 52.99513843228945, 3], [-58.06242762088598, -21.045764653243808, 54], [-48.86240181918223, -3.744042547437431, 84],
        [7.239526889702574, 29.672175373283352, -77], [-26.76535028491405, 40.87200208675981, 91], [9.215334846816745, -34.89995232229468, 73], [56.5472374694767, 41.03673243216301, 55], [-64.96707921134917, 38.509069294894346, -27], [34.761843980486674, 48.61107975011549, -53], [70.29389670252763, -10.345106760483745, 45],
        [32.574480840164114, -35.20662267419921, -54], [-58.01644304921774, 10.433363764053567, -19], [79.70437210296345, 12.520479300392836, 65], [83.97122276973428, -32.59840331634112, -52], [-25.369691799668402, 46.431694798811975, 83], [-70.75828026659295, -8.975490043569465, 38], [-65.33894929767128, 39.89672148068175, -3]
    ],
    D: [
        [-87, -30, 16], [-80, -56, 72], [-88, 35, 70], [36, -10, 57], [-69, 89, -37], [-66, 21, -1], [53, 64, -32],
        [51, -35, 18], [-80, 67, 28], [27, 29, -81], [-26, 4, 99], [41, -35, -49], [56, 37, 1], [37, 62, -63],
        [51, -43, -34], [31, -66, 49], [-88, -42, 74], [-9, 8, 86], [-56, -40, 7], [21, 8, 55], [52, 76, -13],
        [15, -21, -71], [-13, -45, 72], [58, 20, 37], [-76, 72, -47], [7, 39, -63], [-93, -1, 16], [-13, 52, -71],
        [-63, -20, -14], [-27, -8, 90], [-73, 19, -19], [24, 9, 48], [-22, -50, 85], [58, 71, 2], [0, 20, -68]
    ],
    E: [
        [1, 77, -75], [-76, -83, 52], [50, 80, 4], [85, 13, 68], [-70, -17, 11], [-28, 49, 2], [-20, -84, 4],
        [-82, -6, 66], [17, 74, 6], [81, 72, -70], [-67, 73, 72], [65, -77, 90], [33, -8, 15], [-67, 83, -87],
        [72, -59, -96], [-39, 19, -74], [9, 52, 79], [27, -28, 3], [83, -50, 63], [85, 48, 0], [-70, -70, 91],
        [-70, -38, 41], [71, 86, 93], [51, -55, -4], [-75, -41, 91], [48, 40, -10], [-6, 21, 71], [68, 73, -76],
        [72, -3, 90], [70, 50, -72], [16, 92, -78], [-66, 36, -30], [44, 74, 7], [-72, 28, 80], [48, -91, 87]
    ],
    F: [
        [-26.68469091735726, 35, -86], [-79.27614543975699, 55, 56], [53.196814327167175, -55, -71], [78.11906954590555, -43, 8], [-27.122977268230656, 83, -5], [-54.443856161689986, -80, 32], [-47.94257359883772, -27, 86],
        [-61.9514916271057, 90, 76], [52.54734525316141, -37, -83], [-65.0720235454098, 99, -74], [-44.76967575788404, -4, -91], [76.05726805167862, 32, -100], [-54.49367478487154, 25, 46], [33.58590049718438, 83, -70],
        [12.7798566582575, -9, 3], [34.61147318850746, -55, 11], [77.1223779758053, 41, 20], [-70.33826061509737, 0, 43], [75.18707549467999, 68, -67], [-7.612272586017397, -37, -73], [-23.114792757213834, 88, -6],
        [-54.46060033332406, -24, 76], [-82.03975031034686, 51, 56], [-19.644132879027012, -52, 11], [87.1850185412705, -22, -14], [12.901891471840145, -50, -83], [-70.62820599494064, 54, -80], [51.576964185609626, -53, 18],
        [40.27279859757206, -26, 7], [83.4036577889525, -87, -84], [-17.55990081809982, 34, 7], [73.47199721542935, 5, -81], [18.552121097116682, -39, -73], [-86.62486015855087, 9, 101], [-70.6719245743568, 63, -86]
    ],
    G: [
        [-10.806971779786224, -46.28148414290508, 80], [2.0013553877919605, -12.615812677110938, 101], [-70.07388244057998, -9.790837804015581, 24], [55.44741526853289, -78.57254519904939, 4], [84.26426276449232, 29.771644910270766, 13], [-78.02026103402207, 55.787845867064306, 21], [62.198247039339066, 2.601883716806583, -81],
        [58.586805082351304, 1.3604410977358725, 14], [-68.08021894154328, 54.664450376479266, 22], [-37.98425695829849, 45.66856591826789, -65], [44.43389293765003, -30.313065861883615, 77], [-9.655284777790357, -74.93659282935225, 60], [-20.29085185202443, 7.372976612803939, 70], [-43.958476877435224, -21.12715907399395, 48],
        [49.51580760095041, 12.860283776721289, -79], [65.72550021663366, -62.35826041461001, 35], [36.138026652683294, -50.71517500236047, 70], [-84.51524949920949, 34.15490388138548, -1], [63.78813400753799, -1.6193775356055085, 12], [27.662459636848382, 71.10830334952675, 6], [8.121174897754244, 5.716753794192883, 68],
        [-35.81882227660965, 67.56006965424734, -63], [31.49314055286436, -56.668486371505345, 4], [32.638641576536514, -9.62837910365917, 57], [-55.89019040971428, 25.264026170298052, 63], [-55.8441496343621, 71.6312670560946, -22], [-33.30873328181778, -58.61700200924228, 68], [17.841542102636836, -10.10391962401863, -71],
        [50.04982734484662, 24.061984889256905, -66], [-33.579506512362556, 10.553745331384263, 94], [-54.802217105513115, -32.25505577889801, 28], [-15.309427164816796, 3.8631714681305453, 68], [-52.235728702576594, 41.89034223790415, 27], [-45.43475723980024, 35.75061780832513, -47], [86.38921195715827, -7.262678445540715, 35]
    ],
    H: [
        [-66, -21, 48], [51, 96, -61], [41, -12, 1], [77, 99, 67], [-49, 2, 83], [84, -38, -75], [-88, 57, 94],
        [-48, -68, 5], [54, -52, -74], [58, -65, 43], [36, 75, 11], [-81, 67, -16], [-57, 38, -86], [-89, 67, 39],
        [-77, 21, -55], [38, 47, 89], [11, -44, -3], [54, -49, -60], [89, -8, -46], [-67, -18, 57], [-52, 87, -93],
        [-65, 81, 36], [70, -52, -75], [17, -83, 6], [68, 24, 49], [-76, -10, -50], [34, 84, -3], [-32, 91, -1],
        [52, 98, -86], [-68, -62, 55], [-58, 21, 41], [78, -89, 30], [14, 58, -5], [77, 6, -78], [54, -39, 92]
    ],
    I: [
        [-29, -44, 81], [-8, 25, -39], [7, -20, 52], [-36, 66, -91], [-5, 89, 23], [-32, 60, 94], [38, 94, -97],
        [-22, -52, -42], [-15, 93, 1], [-11, 6, 23], [-23, 62, -94], [-24, -76, 98], [23, 28, 88], [70, 29, -82],
        [19, -51, -2], [59, 57, 96], [-63, 73, 84], [-18, -53, -26], [-12, 68, -68], [-10, 92, -3], [18, -32, -95],
        [19, -70, 44], [54, 63, 89], [24, 11, 3], [8, 15, -94], [70, -61, -85], [3, -5, 44], [-30, 20, -82],
        [28, 3, -96], [54, 89, 95], [-67, 11, 87], [-37, 81, 80], [-42, 79, -94], [-6, -62, -21], [-1, 35, 44]
    ],
    J: [
        [63.74847388656772, -36.77421972766588, -8.237028276098755], [-52.92130658345036, 2.4618773624583383, 8.797197401929097], [72.04612818740117, 80.08040329088564, -74.94077247392401], [-55.94013926781706, 39.79798700727832, 54.24764903730437], [68.76679131203525, -11.426200962951498, -99.10428844346528], [38.71043886079275, 68.15340230307018, -50.039854802999486], [12.190707260840611, -37.56296647538302, 82.28622685360708],
        [53.30794728611431, 57.61568156356202, -51.901132225877745], [51.91746398417325, -60.47060487915121, -85.32495299629753], [61.33946051735785, 2.6590503431446564, 2.3983609599573157], [-59.193574854796175, 5.018770472038824, 54.71742326423566], [-16.590793844938574, -3.436744491520898, 64.96353549652736], [55.34483826413012, 80.84925536790439, -9.171660696916646], [25.513995855670302, -66.65997047126675, 63.86719217844907],
        [-55.997822036018846, -29.220777678941218, 36.29169820867452], [76.8583386233706, -57.15952749930667, -62.23865517515758], [21.33773195885516, 33.92920967284803, 90.00904485679592], [-46.17888514965675, -14.12678887639936, 78.09978209269738], [45.783748716257335, 49.31648740879075, -93.68248919200919], [58.05823132430848, -36.73977073199047, 50.82294382329056], [52.209060211654815, 57.16143666531303, -9.638222116312704],
        [66.80610702700517, -8.721556714061578, -50.03693080961322], [-20.177869702263102, 56.89467435197136, 69.38119071180839], [40.40966465589507, -87.59601256311072, -48.4322626061518], [-60.62435829055815, -7.947067171476604, 42.019895710397066], [31.73794696719446, -19.303215475218465, 92.67174308280929], [49.651858801837136, 24.581158967861015, 32.1415191473653], [-23.523274243796052, -27.63210801723052, 74.83805633298613],
        [42.414393767652385, 43.31469609978146, -101.27821690762704], [47.081171777662775, -26.37350373803676, -77.64737906018915], [-2.333776725138634, 8.005042128645869, 73.34779199315014], [60.13542159725455, 33.5727326575653, 5.70015318345078], [-63.30550544142115, -21.259181260281835, 11.202958557760468], [-9.231120216095533, 42.00131568564021, 85.69398274373012], [40.20604565880727, -43.278148093284756, 60.49172791875057]
    ],
    K: [
        [-48, -83, 77], [-88, 87, 69], [8, 46, 33], [56, 5, -56], [53, 76, 46], [-45, 8, 65], [-62, -21, -41],
        [-75, 71, 27], [73, 75, 41], [70, -16, 43], [60, 83, -55], [-64, -37, 39], [60, 1, -55], [-84, 69, -65],
        [32, 5, 53], [-83, -65, 50], [-39, 22, -99], [-82, 100, -11], [-71, -65, 88], [-73, 81, 89], [72, 20, -41],
        [-76, -28, -24], [-3, -58, -38], [90, -58, 71], [-52, 78, -4], [-80, 55, -49], [62, -35, -54], [-80, 26, 59],
        [-76, 49, 65], [62, -90, -65], [41, 90, 63], [82, -22, 78], [-66, -18, -40], [-49, -20, 86], [34, 73, -30]
    ],
    L: [
        [-76.92281040976768, 15, -60], [-72.18093486092478, 53, 43], [-79.27030794473605, 59, -7], [-37.506753268693174, 35, -64], [7.880611828239495, -71, 80], [1.6367580081659128, 89, 99], [54.36128720342603, 28, 65],
        [7.8736398836955175, -46, 60], [50.992105238613505, 21, 90], [-56.72268625023095, 98, -51], [28.955550810945482, 97, 60], [-62.86395878864362, 53, -26], [-45.49392340729404, 81, 24], [-33.68996775718711, -93, 74],
        [-42.92607147810353, 62, -5], [-79.04525878211193, 69, -58], [-54.95627047223268, -4, 20], [45.83167144183362, 12, 87], [-31.595287993043925, 99, -56], [75.16148164393292, -78, 41], [-28.8028349223474, 39, 86],
        [-0.053352745713667105, -27, 79], [-39.65744191050278, -93, 10], [8.670102090377384, 75, 62], [77.19644179633553, -57, 90], [-68.4210408023222, 51, -75], [-64.80713140434615, 89, 36], [-67.91652734119756, -65, 1],
        [65.31629877937124, 67, 36], [74.83584595908675, -47, 39], [-70.79212362804901, 55, -86], [-34.79484509682737, 79, 89], [-36.92195405650306, 95, -50], [-21.437688147142055, -60, 89], [66.50234724125704, 39, 78]
    ],
    M: [
        [56, 33, -72], [-27, 84, -7], [92, -40, 100], [-103, 82, 47], [100, -15, -10], [30, -94, 32], [-105, 28, -36],
        [83, -4, 67], [118, 60, -22], [100, -19, 17], [24, 74, 81], [6, -41, 75], [-95, -46, 26], [-53, 92, -68],
        [4, 60, 83], [82, -77, -93], [-100, -32, 69], [111, 69, 96], [-82, 17, 89], [-94, 91, -1], [104, 57, 7],
        [-53, 52, -98], [-90, 85, 28], [105, -25, 72], [-91, 71, 93], [-103, 76, -21], [39, -95, -5], [31, 15, 17],
        [118, 14, -96], [-30, 22, 58], [32, 95, 3], [113, 27, 65], [24, 61, 21], [19, -54, 28], [-107, 21, 42]
    ],
    N: [
        [-60.05367428253528, 87, -89], [74.34155308150281, -77, -73], [-59.28859816802628, -57, 77], [89.21738099511714, 46, -65], [-2.1958475499171897, -94, 2], [61.396504423965865, -23, 98], [-90.5938960914734, 50, -10],
        [-3.206127537036622, -43, 14], [67.78008682951366, 41, -25], [-89.00167512818423, -72, 80], [-75.82705341588341, 41, 71], [15.467789135265704, 9, 78], [74.54438544701443, -81, 25], [92.43362166218975, 86, -38],
        [94.24278783788233, 29, -22], [-67.8787177372902, 33, 48], [-80.8895309775754, 11, -30], [99.39909814540445, -91, -65], [14.994928177846177, 9, 45], [95.69120270056851, 52, 43], [-1.649974242028975, 94, 6],
        [7.571023870194722, 12, 35], [92.79586583784138, 9, 53], [72.24653965280717, -60, -27], [-71.46589030848693, 85, 77], [-20.54921857555442, -34, -77], [-62.30179687993009, 14, 61], [74.63208947858166, 74, 11],
        [57.38559707318689, 58, -77], [82.56113373385773, 31, 56], [-56.350469824027535, 9, 80], [-58.738195058185916, -69, 50], [29.656672215093067, -92, 39], [-64.45524505840518, 40, -84], [42.84062573096017, -21, 80]
    ],
    O: [
        [75.65247941268908, 43.96092858721718, -52], [69.62217467162279, -63.32844095006283, 20], [57.14297556728451, 63.65578541830817, 8], [-34.13332988170032, -47.71796695494747, 52], [54.04599758694326, 15.669950324434453, 87], [16.19806737123706, 19.09140569991068, 55], [-6.423927255637352, 5.643463679126896, -70],
        [46.14579775519309, -39.488898581938344, 43], [-50.056189156026335, 66.64199459692617, 32], [58.65110845955036, 73.68356841657317, -40], [86.39286850018732, -54.17240148086249, -10], [39.693127665258345, 42.55039431689485, -83], [24.947459442915573, 61.88775240102146, 62], [-51.25893555312941, 86.72383013367718, -20],
        [-52.800895619573666, 40.65507021794431, -35], [-38.87282254370457, -43.922661977758835, 41], [-37.006386833536126, 81.19065960349891, 42], [24.257628514102322, -80.09269428234242, 56], [5.338899096885498, 36.81112288790084, -85], [67.47820347181892, 36.67367649835256, -18], [-51.65098063690352, 32.427743397000825, 82],
        [-62.35173626975549, 5.845013073483457, 53], [46.29074987285463, -29.735349719097243, 37], [76.29419717283744, 47.19003717870231, -10], [59.60869597054784, -42.195452792658976, -1], [-32.59877165569709, 54.35256226591875, -58], [36.747673320284974, -20.040204283294017, 91], [44.87436005102438, 40.80023268432464, 32],
        [-42.331234587612066, -31.316957224090316, 57], [32.285563182834665, -50.790159871552824, 70], [33.50073655843539, -12.66905593882366, 50], [34.88088289323596, 38.30214905961702, 76], [94.68481456937099, -20.361888897733877, 37], [14.68589950255577, 37.81071956055152, -71], [-51.10595984003672, 1.5687226539498482, -26]
    ],
    P: [
        [-44.29111389086602, -91.66313866024686, 35.35065402399346], [-47.60083507804787, 31.34942455598545, 99.06818762188601], [63.9427814844457, -56.7263760813453, -10.399194255535605], [-54.95436199929079, -57.00862860122164, 66.4576153569067], [-92.30397266641934, -8.541584286049513, 14.944217654375862], [56.270846837803816, 71.71198815258953, -32.62551513612766], [-50.862780298312884, 84.93427382535072, 19.005681558305383],
        [-63.425052989963355, -80.09854788552369, 33.39379658759802], [-90.07988323825626, 54.93868218797334, 91.3100662008716], [-57.87071676717752, 80.99243529126869, 26.928512553466618], [55.945961289552855, 14.024815400376426, -64.94304261301025], [31.3484210684082, 29.994776673000956, 45.40930394342888], [38.23474429174567, -58.528034705798895, 23.903313006234214], [-19.81254831053721, 8.776718164070132, 46.90663130513798],
        [27.502810750614714, -101.35087855278418, 37.17023039892604], [43.053925184698926, 73.5268229402597, 8.514709402462572], [-81.00856592443085, 81.08320337582035, 17.567330436161456], [-80.42943434601996, 18.612237191566308, 83.78728753694406], [-1.5461412778951313, -13.302840545151497, -94.38491462843884], [79.06858073287628, 30.91198809842129, -40.236015474431156], [-45.050036997423256, -32.86104421972507, 85.54321757776316],
        [-75.34115780642402, 104.76736837893404, 73.75982494693386], [-73.33838172224885, 9.047664319871942, 97.24734478437372], [47.55100523623594, -91.2730056004439, 11.547559821869825], [-91.22313359858761, 32.01077351868824, 3.76902532457936], [-72.47465764043842, 20.142183616071975, -66.50271966863427], [58.01289019424931, -13.098787105407098, -36.910642073897606], [16.635929378617675, 25.55046969586897, 39.12858422973119],
        [55.821496223661825, -56.453575935421554, -9.267724594958622], [-44.15104650776208, -72.7299440272802, 96.9920555661962], [-78.79540294585422, 30.753263506475832, -4.6471241619686054], [48.22538709212995, 14.108038771485752, 43.95183361255488], [-59.38436590413223, 70.55518736255652, 37.07237310935946], [-48.85860793524761, 12.96274222126523, 98.81903663184022], [-57.004317713996414, 66.79250542845764, -67.97317530782777]
    ],
    Q: [
        [-0.311975790884091, 84.94491536456442, 4.654960227285812], [-4.165174447545198, -58.60138056699243, 101.43895531840039], [49.69374995817037, 67.37223878806097, 26.726034193559286], [14.17094308252939, 22.858651617733415, -98.79277272575057], [-3.838781165216261, -62.24080500299611, 73.76250677670473], [-76.69388294314345, 29.00954890543457, -44.57314130479426], [80.60300764717954, -37.26644497256012, -28.533219114388828],
        [36.922304459298715, 54.53099440691045, -94.52088384779239], [-70.14527332761742, 54.929668270779814, -62.34457303478495], [66.87039111460703, -14.83471967144935, -36.81656722885164], [0.4015415900685184, 4.540492364943304, 47.71481382770645], [-51.83615240363803, 7.283484144972476, 47.21697178531309], [-7.157402111356703, -54.33669619490561, 100.50885262946636], [-88.5059307502892, -4.353954808257171, 14.126412843349556],
        [-72.01553621664294, 31.409479597599073, -35.96246970189473], [-32.28827223010801, -40.08285905081544, 41.95624095085688], [75.42311235792559, 30.350533354076674, -31.795140992394835], [77.33125912924541, -33.15794301724041, 16.61046480056109], [65.40742216525516, -64.80140705758387, -19.494305595338375], [-4.015455636150946, 53.02329022087525, 94.93380052661789], [31.448074036979023, -10.056249745326454, 63.29407472386336],
        [16.546858403728933, -5.955935015406794, -87.62233032703895], [37.5023917289607, 32.084558021212985, -90.67273497030764], [71.35224760904319, -58.39582104686442, -20.31826311176158], [-30.95002498049128, -2.5354909459217936, 45.60250471451499], [-78.4992146564144, -44.362964833775145, 26.89012923842705], [-3.647649963866982, -19.121177907054616, 111.5285229388908], [58.867023118681146, 64.16190505565618, 8.026679972686907],
        [-57.630056279185254, 0.5843034037293222, 38.18535892538486], [-4.143980970034747, 51.94114256138752, 111.6429969523627], [77.89002761856321, 45.06122011647744, -30.623471247557873], [83.25231760139314, 15.109348816753823, -81.49361787932602], [-43.95075392545102, -36.17567303671986, 52.30393357524008], [4.162237350163764, 64.23246893417942, 52.046567649253774], [-66.00961072292489, 75.48169674998618, -30.66706072355116]
    ],
    R: [
        [38.325661888366994, 21.50661619712227, 34.96339587003317], [-86.47750772018765, 80.71251803589445, 7.188337368488547], [-61.327330508577646, -93.14212221692598, 69.79030534807399], [-85.84018458691328, 8.17875765802627, 39.962391644862805], [38.31493425961287, -53.21906330388654, 18.338559303155037], [25.17125454059058, -67.06855886255272, 82.18931498783923], [-62.69511135092648, 32.15790746583514, -52.47278547450738],
        [-35.6733513652751, -88.63030088385291, 25.804928602649355], [79.19899681748649, 43.355777892843776, -12.83890944362902], [-68.4921713486663, 93.68870212345598, 70.39747848562124], [59.18934367368314, -21.173727372533193, -18.504578841420752], [-17.12470167730814, 7.343431348435473, 25.65377654911711], [-56.077803470468986, -54.10048609481921, -9.365008642914134], [33.28171547231122, 80.99630922743518, 65.3439951759969],
        [5.431300750317135, 41.88088418400437, -89.11370286292006], [60.30339661709937, -34.4740901905476, 55.571747358766146], [34.17184176470988, 67.74663420978968, -7.1270001465482125], [43.82474446108637, -55.30369974238604, -49.48596049727519], [-59.6675472212557, 78.57668211784349, 8.366251106028429], [-63.815095635009996, -74.206331947271, 5.810854652901966], [53.63903492824221, 22.755258240322675, 80.26245568843842],
        [-65.52284116759441, 58.65513844265868, -58.19826479920546], [-5.216952799707826, 32.83623806088582, 21.925726470239113], [28.470816587195415, -63.295324706490064, 39.788159011061055], [-87.3978662846524, 61.26294617930907, 50.53535149462513], [-30.76621692825936, 95.16532395409396, 26.80111027509959], [31.14407011089881, -2.874451681565816, -92.6260757624834], [45.75630274996357, 91.51267090195412, 33.03052337974924],
        [-28.7985373762901, 98.5377814157055, 33.98351829360206], [-20.395210880279706, 24.7863732672529, -85.28547860355985], [17.142082798177142, -81.0901228796064, 23.086051731585613], [-69.47722003440045, 36.149506802523774, -50.07799461370492], [-52.658434997067445, -24.711824327755266, -3.4888542024491755], [89.31265983666637, 5.815135666336758, 3.7650050065632144], [55.65313396745236, -7.885862746279017, 24.279031819093234]
    ],
    S: [
        [90.0304027683544, -18.21580455263853, 32], [1.3158427554320724, 88.39161208906972, 4], [-68.43516875926471, 14.547723257653274, -14], [30.38443950130113, -40.35118769407174, 7], [55.26806994391953, 23.571444610003766, 23], [4.842622327619935, -52.89017942280389, 80], [23.649773961303197, 14.762809747799452, -78],
        [-43.927274992339065, -28.762569714455637, 86], [-62.59127095307228, 71.14013920421735, -31], [68.58399421303585, 1.6549456071285533, -69], [66.75391602513244, 38.211046150500664, -67], [75.79829579180378, -57.20017094758222, -1], [58.38782235869047, 12.460041629129094, 74], [11.081439820736406, -19.862797686104145, -6],
        [-51.45766463898927, -28.0386966296886, 82], [21.61178732744959, 81.180690379754, 0], [-43.61417272743355, 2.761754672217009, -8], [-45.21254617946699, 68.29279652179329, -4], [56.45832895353669, -14.78846361915626, 76], [48.81208854740356, -9.9791581617596, -78], [-23.942325621795334, -54.81123938844345, 85],
        [-6.80225387517784, 30.53934343647032, 99], [0.5124384448462109, -71.69777642331528, -3], [40.29104645742797, 16.73847594207882, -89], [76.1072100082043, -39.69602872756863, 42], [-34.939936071698526, -20.356001996966455, -1], [25.304429664676462, 19.641625380731142, -2], [-6.729285513283159, 8.055162523196188, 79],
        [84.12014370222622, 21.914862128534367, 36], [71.0754344512138, 14.356556484667564, 9], [-23.365778759646318, 97.45045245539623, -8], [19.701118404722024, -13.379377067512848, 93], [50.58038467347964, 52.005607689258966, -72], [-26.641031064717765, 61.3502476858408, 73], [-50.01646452441087, 10.795464367134343, -8]
    ],
    T: [
        [28, -29, 35], [84, 86, -88], [-18, 5, -2], [27, 57, -79], [29, -93, 38], [-25, 54, 85], [82, 2, -96],
        [21, -94, 58], [-14, 99, -38], [25, 44, 12], [6, 73, 96], [-10, 26, 95], [-69, -7, -68], [-17, 27, -86],
        [-18, -57, -25], [-17, 99, -25], [27, 39, 17], [-28, 58, -89], [56, 92, -69], [-7, -60, 53], [93, -61, -44],
        [-74, 30, -79], [1, -55, 52], [-28, 51, -77], [87, 59, -90], [-37, 95, 99], [15, -2, -2], [2, 87, -50],
        [-18, 75, -17], [-12, 89, 76], [-22, 22, -89], [-16, -88, 47], [-56, 91, -59], [-20, -32, 82], [25, 10, 15]
    ],
    U: [
        [55.66166370700276, -32.52102326387745, -8.862088842571282], [-83.14510429842791, 18.373080788580978, -35.52863165895485], [56.36402214997403, -12.56179033344118, -68.45809761698692], [-56.00796612581237, 41.25555789410964, 23.638930607647012], [48.388467975930695, 40.796809491294056, 54.04299325186065], [7.661203733691494, 44.41766178043801, 90.66504657624506], [17.40355089515714, -26.21355573467558, 95.60791587584526],
        [-33.62574926777471, -40.556614023222224, 69.86023137453944], [69.00904466653927, 5.550112159412052, 62.59701534668156], [78.07428624338263, 58.66080952327866, -100.34121114644475], [49.902046755599, -28.49496035120457, -102.4639736355743], [-75.92540864134696, 44.52675574902497, -12.6900188062733], [2.9566589327268247, 41.339924060005615, 68.52743964115788], [70.16694910812228, 46.03418194766144, -15.344528417309803],
        [-8.927941027020822, 37.68323582919339, 93.67925284040814], [70.25888638479404, 3.016318230138964, 41.23841594858851], [-75.81061311220725, 65.06311069206473, -71.6372901491383], [33.795911621535, 60.515468989119626, -96.67119252934467], [80.9676633635859, -10.119149435340717, -78.25346726463698], [-54.57403765130578, 21.615567936221098, -9.631395817089938], [-49.46594362046668, -62.054616739580155, 34.8295961837423],
        [71.05103868411896, 22.866219972184695, 1.073336390920204], [-67.34459492709604, -8.258894414982379, 61.45217136178499], [61.39742260150368, -76.76653071008661, -70.15241878763524], [2.1528342666944837, -11.388978337568247, 69.170895559325], [-70.5733338233044, 46.966130893703195, -10.778657989922777], [69.46986471298848, 26.472370165545485, -51.80630978912742], [-58.88465122288464, 92.43975919817152, -76.54143688890942],
        [21.771541232391314, 22.3348874781676, 63.091519478264445], [60.10436395510004, -4.63484764238855, -6.485553860383703], [-66.1363791999431, 57.40354998526473, 14.358060408847251], [45.40658645577241, 41.66855641657685, -70.90900549436424], [64.79706597901202, -63.04907606364295, -45.1370301705693], [-73.06403213481931, 40.360381936145046, -47.59906980243371], [-65.66465265906594, -9.932444547434287, -56.97055329985166]
    ],
    V: [
        [45.091010330212896, -41.365025964941196, -43], [54.13684328755545, 66.01432390938467, -63], [38.021068014288005, 43.7235731505742, 40], [-26.52656767938666, -1.7026370798819812, 98], [37.469267793790664, 13.463683523635666, 66], [-39.18361356941223, 69.66816955709442, -8], [89.79810871903555, -35.44015601529172, -58],
        [-52.17582823946956, 74.39436736999426, -50], [62.90930623356479, -40.02017689832623, 3], [-44.2538498571231, -15.165973098554574, -46], [48.021307183659786, 26.267662268291787, 48], [-60.772929851889685, -12.224609128143825, 21], [60.51744277285465, 42.81455206221122, 0], [7.174362277410978, 61.61767044337154, 100],
        [50.717233425335564, -31.02778442467941, 9], [-56.805215945912444, 9.297995126572033, -51], [43.983557338844776, -34.27598931698037, -15], [-61.0933156795698, -8.45333193563205, -8], [-18.582172389226358, -58.95113993798663, 95], [57.58814093019464, 24.83712141664583, 34], [78.55828945327968, 24.141274667096468, -74],
        [35.755067969520894, 34.29284107355622, 80], [78.91190662942654, -6.782028592041613, -98], [42.97691379372378, 69.2733934120885, -5], [71.39956341605773, 66.09632008244711, -77], [-18.029696939622553, -5.151201370767623, 98], [45.193371144465004, -14.913780476264776, -46], [54.344084441981664, -51.61427981214627, 8],
        [64.112589801875, 55.09152641314205, -17], [-44.12963860392091, -7.287016587069417, 57], [60.41251502262293, 50.350357190284626, -75], [-57.68018382997506, 25.93350935152614, 38], [12.197457290198884, 42.721350043599784, 91], [84.86610157668076, -26.263950338941797, -66], [57.685414107045204, -2.5421847727079125, 40]
    ],
    W: [
        [15.08589090480882, 10.290994563787791, -73], [-81.05102139570545, -22.880261167785477, 63], [91.84524094885114, 78.55767764438019, -80], [-19.94937348337821, -17.314378857723042, -38], [24.54545600485983, 39.77228501027366, -24], [116.63636916349924, -62.21779873940201, -94], [78.90941714614073, -15.192068256021471, 64],
        [-36.22979733835858, 40.06839578427531, -7], [-97.53955088839714, 28.046385258584564, -58], [43.25165311096154, 31.171622643977912, 57], [46.42784854940314, -45.88967727540624, 37], [120.29461390576728, 22.934649338014594, -82], [17.88835998249627, -35.562288398426475, -75], [-37.85410879488148, -51.712056050226174, 42],
        [95.25458624441947, -74.23400780819233, -73], [34.97585165248549, 9.818834883825048, 74], [-25.35679375175377, 38.11050936354221, -56], [-37.57110219191085, 48.185538508136034, 78], [-15.275388995409685, 87.29297827115573, -97], [102.52109045575699, -23.292613040496747, 14], [-88.16451714647492, 44.772879291879605, 18],
        [96.52531036807804, 56.41839097292677, -46], [-24.665370269924736, 83.69677006629092, -38], [13.80538271702406, -23.529139749760162, -50], [63.92367011288991, -29.17291842887883, 80], [-100.12656736701987, 15.588068820193968, -36], [126.94964386315053, -46.278678165602486, -88], [40.56723888445359, -6.655279212960835, 38],
        [0.9919070474452383, -29.726366020195556, -92], [98.11033087976844, -12.529278834946055, 8], [41.049064040029236, 42.08808558548526, 17], [-66.49027417764869, 26.298907188132688, 100], [97.96923955976638, -36.35100642760575, -68], [115.90890640346947, -2.4212836575995453, -86], [-105.16974716463153, 50.42206082626362, -61]
    ],
    X: [
        [63, -27, -85], [-72, -44, 97], [23, -87, -36], [-62, -13, -69], [30, 24, -31], [20, 64, 35], [46, 33, 95],
        [-41, -51, 71], [66, -96, 76], [-48, 89, 55], [42, -89, -64], [-4, 3, 6], [56, 68, -60], [36, 81, 33],
        [-40, 21, 58], [45, 1, 40], [35, 76, 30], [58, 66, -54], [32, -70, -37], [-72, 66, -93], [-65, -44, 78],
        [-45, 73, -55], [32, 0, -30], [39, 60, -86], [70, -38, 81], [-22, -61, -34], [51, 45, 70], [-44, -4, 49],
        [-73, -52, 72], [-95, 98, 97], [44, -71, 49], [-53, -4, 85], [7, -99, 5], [50, -75, -70], [27, 61, -47]
    ],
    Y: [
        [14, -17, 69], [-41, 81, -83], [-64, 6, -69], [50, 99, -41], [-10, 35, 28], [-23, -91, 12], [-20, -53, 86],
        [-65, -10, -55], [-3, 35, 10], [40, -48, -17], [-6, 86, 50], [61, -98, -51], [-11, -42, 26], [82, 1, -80],
        [40, -61, -25], [-26, 9, -28], [-11, 78, 4], [-8, -46, 48], [-68, 86, -91], [56, 1, -70], [27, -52, 87],
        [-25, -61, -7], [28, 18, -18], [58, 98, -78], [15, 93, 82], [18, -39, 30], [59, 60, -97], [-29, 66, -61],
        [15, 37, 96], [-39, 67, -59], [36, -16, -56], [24, -99, -43], [72, 33, -77], [-24, -33, 50], [9, 60, 45]
    ],
    Z: [
        [-71, 64, 35], [-26, 49, -86], [86, 35, -72], [-14, 32, 73], [-11, -17, 97], [57, -52, 89], [38, -50, -29],
        [54, -89, -37], [-19, -78, 82], [44, -6, -78], [-67, 15, 74], [-79, 72, -51], [66, 87, 95], [43, 10, 85],
        [-44, 90, -63], [66, -83, 94], [29, -95, -20], [16, -38, -22], [-35, -16, 85], [52, 92, 91], [19, 58, -97],
        [57, -38, -35], [-29, -82, 79], [-55, -97, 52], [72, 15, 67], [-44, 68, 21], [10, 85, -70], [1, -10, -63],
        [87, 21, -46], [0, 99, 10], [-56, 49, 51], [-13, -44, -61], [65, -2, 99], [44, -99, 64], [-68, 90, 68]
    ]
} as { [chr: string]: [number, number, number][] }