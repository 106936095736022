import { Vector3 } from "three";
import { ExhibitionGlyph } from "./ExhibitionGlyph";
import { exportToObj } from "./objExport";

export function exportObj(
  glyphs: ExhibitionGlyph[],
  translate: { [glyphId: string]: Vector3 }
) {
  let combinedVertices: [number, number, number][] = [];
  let combinedFaces: [number, number, number][] = [];

  let vertexIndexBase = 0;

  for (let glyph of glyphs) {
    if (!glyph.isosurfaceData) continue;

    let vertices = glyph.isosurfaceData.vertices;
    let faces = glyph.isosurfaceData.faces;
    let translation = translate[glyph.id] || new Vector3(0, 0, 0);

    for (let v = 0; v < glyph.isosurfaceData.updateRanges[0]; v += 3) {
      combinedVertices.push([
        vertices[v] + translation.x,
        vertices[v + 1] + translation.y,
        vertices[v + 2] + translation.z,
      ]);
    }
    for (let f = 0; f < glyph.isosurfaceData.updateRanges[1]; f += 3) {
      combinedFaces.push([
        faces[f] + vertexIndexBase,
        faces[f + 1] + vertexIndexBase,
        faces[f + 2] + vertexIndexBase,
      ]);
    }

    vertexIndexBase = combinedVertices.length;
  }
  let obj = exportToObj(combinedVertices, combinedFaces);
  return new Blob([obj], { type: "text/plain" });
}
